import React, { useState, useEffect } from 'react';
//import { Box, Container, Backdrop, CircularProgress, Grid } from '@mui/material';

import {
    ListItem,
    Card, CardHeader, IconButton, CardContent,
    Divider, Box, Tooltip, Hidden, Button,
    ListItemText, ListItemAvatar, Avatar,
    Backdrop, CircularProgress, Grid
} from "@mui/material";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import ToolbarHeading from '../components/ToolbarHeading';
import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, getAutoCompletedata } from '../utils/CommonGraphql';
import ListModule from '../List/ListModule';
import { red } from '@mui/material/colors';
import EmailIcon from '@mui/icons-material/Email';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    productCard: {
        height: '100%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const ListItems = (props) => {

    let query = useQuery();
    console.log("query", query);

    const classes = useStyles();
    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData)
    const ContractorData = useSelector(state => state.ContractorData);
    const cid = useSelector(state => state.cid);
    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        BoxWidth: 6,
        AllowAdd: true,
        ListType: "ListModule",
        CompanyId: UserData.CompanyId,
        propss: {},
        key: NewPageId("win"),
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {

        console.log('SMSSetup.params', params);
        console.log('SMSSetup.props', props);

        if (props.Data) {
            setState(prevState => ({
                ...prevState,
                Category: "EmailTrigger",
                ...props.Data
            }));
        }

       // GetDatafromDB("EmailTrigger", props.CompanyId, "DataBack");


    }, [props.Data])

    useEffect(() => {
                
        let NewId = `${ContractorData.CompanyId}::${props.CompanyId}`;

        GetDatafromDB(state.Category, NewId, "DataBack");

    }, [state.Category])

   
    const GetDatafromDB = async (Category, pk1, ArrayName) => {
        SetLoading(true);
        console.log("SMSSetup.NewData1", Category, pk1, ArrayName);
        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: ArrayName,
                    ArraySearchString: ":",
                }
            ]
        };

        let NewData = await getAutoCompletedata(DataString, pk1);
        setState(prevState => ({ ...prevState, ...NewData }))

        SetLoading(false);
      //  console.log("SMSSetup.DataString", DataString);
        console.log("SMSSetup.NewData2", NewData);
    }

   
    // Additional Information Box
    const AddBox = (add) => {
        console.log("SMSSetup.AddBox", add);
        let NewKey = NewPageId("emailtrigg");
       
        //open Drawer
        setState(prevState => ({
            ...prevState,
            //DetailComponent: add.DetailComponent,
            DetailComponent: "UrgencyDetails", //state.NewLoadComponent,
            Category: state.Category,
            ShowDetails: false,
            DetailsProps: {
                id: NewKey,
                Category: state.Category,
                ShowEditButton: false,
                PreviewEdit: false,
                CompanyId: state.CompanyId,
                CompanyName: state.CompanyName,
                CustomerType: state.CustomerType,
                CloseDrawer: (e) => CloseDrawer(e),
                ActiveCategory: props.ActiveCategory,
                Activefield1: props.Activefield1,
                ActiveKey: props.ActiveKey,
                Data: add
            },
            key: add.key || NewKey
        }))

        OpenDrawer(true);
        // }
    }

    /*
     *  const AddBox = (add) => {
        console.log("SMSSetup.AddBox", add);
        let NewKey = NewPageId("smsset");

        //open Drawer
        setState(prevState => ({
            ...prevState,
            //DetailComponent: add.DetailComponent,
            DetailComponent: "UrgencyDetails", //state.NewLoadComponent,
            Category: state.Category,
            ShowDetails: false,
            DetailsProps: {
                id: NewKey,
                Category: state.Category,
                ShowEditButton: false,
                PreviewEdit: false,
                CompanyId: state.CompanyId,
                CompanyName: state.CompanyName,
                CustomerType: state.CustomerType,
                CloseDrawer: (e) => CloseDrawer(e),
                ActiveCategory: props.ActiveCategory,
                Activefield1: props.Activefield1,
                ActiveKey: props.ActiveKey,
                Data: add
            },

        }))

        OpenDrawer(true);
        // }
    }
     * 
     */

    const CompanyData = (data) => {
        console.log("data", data);
       // GetDatafromDB(state.Category, data.pk1);
    }

    const AddItem = (data) => {

        let NewKey = NewPageId("emailtrigg1");

        setState(prevState => ({
            ...prevState,
            //DetailComponent: "NotificationTriggerSetup",
            DetailComponent: state.NewLoadComponent, //"ItemAddPage",
            ShowDetails: false,
            DetailsProps: {
                //title: add.field1,
                Category: state.Category,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                CloseDrawer: (e) => CloseDrawer(e),

                id: NewKey,
                Category: state.Category,
                ShowEditButton: false,
                PreviewEdit: false,
                
                CustomerType: state.CustomerType,
                CloseDrawer: (e) => CloseDrawer(e),
                ActiveCategory: props.ActiveCategory,
                Activefield1: props.Activefield1,
                ActiveKey: props.ActiveKey,


                Data: {
                    mobile: "",
                    Category: state.Category,
                    CompanyId: props.CompanyId,
                    CompanyName: props.CompanyName,
                }
            },
            // key: add.key || NewKey
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
     
        let NewId = `${ContractorData.CompanyId}::${props.CompanyId}`;

        GetDatafromDB(state.Category, NewId, "DataBack");

        OpenDrawer(false);
    }

    
    console.log("SMSSetup.state", state);

    return (
        <>

            
            <Card
            // className={classes.root}
            >
                <CardHeader
                    title="Email Trigger"
                    avatar={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                            <EmailIcon />
                        </Avatar>
                    }
                    action={
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => AddItem()}
                        >
                            New Setup
                        </Button>
                    }
                />

                <CardContent>

            <ListModule
                        AddBox={(add) => AddBox(add)}
                        title={state.field1}
                        disabled={false}
                        DetailLink={(e) => AddBox(e)}
                        DataBack={state.DataBack}
            />

                </CardContent>
            </Card>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={(e) => CloseDrawer(e)}
                Category={state.Category}

                propss={state.DetailsProps}
                key={state.key}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />
        </>
    );
};

export default ListItems;
