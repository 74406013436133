import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
//import { removeEmptyStringElements, NewPageId } from '../utils/CommonGraphql';
import {
    NewPageId,
    Pk1SingelItem,
    removeEmptyStringElements,
    FindLatestDate
} from '../utils/CommonGraphql';
//import { currentTime } from '../utils/DateTime';
import { useSelector } from 'react-redux';
import {
    TextField,
    Collapse,
    Paper, Grid, Button,
    Backdrop,
    CircularProgress,
    Card,
    CardContent,
    CardActions,
    InputLabel, Select, FormControl, MenuItem, Checkbox, Typography,
    RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    Switch, Tooltip, Divider
} from '@mui/material';
//import Card from '@mui/material/Card';
//import CardActions from '@mui/material/CardActions';
//import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
//import Typography from '@mui/material/Typography';
//import Check from './CheckBoxGroup'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerShow from '../utils/DrawerModule';
import { TotalList, schedule, emergency, todo, quote, SMSQuestions } from '../utils/UrgencyOptions';
import ContactBox from '../components/ContactBox';

//import Page from '../components/Page';

var dateToday = new Date();
let currentTime = dateToday.toISOString(); //.replace("Z", "");

const UUid = NewPageId("Urgency");

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

let CategoryList = [
    {
        label: "Location",
        Category: "Location",
        key: "Location"
    },
    //{
    //    label: "Service Location",
    //    Category: "Location",
    //    key: "Service_Location"
    //},
    //{
    //    label: "Bill to Location",
    //    Category: "Location",
    //    key: "Bill_to_Location"
    //},
    {
        label: "Equipment",
        Category: "Equipment",
        key: "Equipment"
    },
    {
        label: "Personnel",
        Category: "Person",
        key: "Personnel"
    },
    //{
    //    label: "Person Approving Cost",
    //    Category: "Person",
    //    key: "Person_Approving_cost"
    //}
    //,
    //{
    //    label: "Person Issuing PO",
    //    Category: "Person",
    //    key: "Person_Issuing_PO"
    //}
]


export default function SimpleCard(props) {
    const classes = useStyles();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const [loading, SetLoading] = useState(false);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);

    const initialState = {
        TotalStepList: SMSQuestions,
        ContactList: [],
        Category: props.Data.Category,
        title: "Pre Select Information",
        ErrorMessage: false,
        disabled: false,
        Selectedkey: "Location",
        NewLoadComponent: "CreateContactUs"
    }
    const [state, setState] = useState(initialState);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {

        console.log("PreSelect.props", props);


        // setting up the date so use Company when Pre-select, and use contractor when contact list
        let NewData = props.Data;
        let Category = NewData.Category;
        let CompanyIdNew = NewData.CompanyId;
        let CompanyNameNew = NewData.CompanyName;

        if (props.Data.ListType === "Contractor") {
            CompanyIdNew = ContractorData.CompanyId;
            CompanyNameNew = ContractorData.CompanyName;
        }

        setState(prevState => ({
            ...prevState,
            NewLoadComponent: props.Data.NewLoadComponent,
            Category: Category,
            CompanyIdNew: CompanyIdNew,
            CompanyNameNew: CompanyNameNew,
            field1: `${CompanyNameNew} Info`,
            title: props.Data.title,
        }));

        let CompanyContractor = `${ContractorData.ContractorId}::${props.ActiveKey}`;

        console.log("PreSelect.CompanyContractor", CompanyContractor);

        GetDatafromDB(CompanyContractor);

    }, [props])

    const GetDatafromDB = async (cid) => {
        SetLoading(true);
        let ContactList = []
        let NewData2 = await Pk1SingelItem(cid, `Data::Online::Lambda::PreSelect::${props.Data.ListType}`);
        let NewData = await NewData2;

        if (NewData && NewData.dataJSON) {
            let ContactList2 = JSON.parse(NewData.dataJSON);
            ContactList = ContactList2.ContactList;
        }

        console.log("ContactList.NewData", ContactList);
        setState(prevState => ({ ...prevState, ContactList: ContactList }))

        SetLoading(false);

    }



    const CloseDrawer = () => {
        //console.log("CloseDrawer", e)
        SetDeawerOpen(false)
    }

    const handleChange = (event, name) => {
        setState({
            ...state,
            [name]: event.target.checked,
        });
    };

    const DeleteItem = (index, Arr) => {
        let arr = state[Arr];
        arr.splice(index, 1);
        setState((prevState) => ({ ...prevState, [Arr]: arr }));
    }

    const MoveUp = (index, Arr) => {
        let arr = state[Arr];
        let indexB = index - 1;
        if (indexB > -1 && indexB < arr.length) {
            let temp = arr[index];
            arr[index] = arr[indexB];
            arr[indexB] = temp;
            console.log("Up arr", arr);
            setState((prevState) => ({ ...prevState, [Arr]: arr }));
        }
    };

    const MoveDown = (index, Arr) => {
        let arr = state[Arr];
        let indexB = index + 1;
        if (indexB > -1 && indexB < arr.length) {
            let temp = arr[index];
            arr[index] = arr[indexB];
            arr[indexB] = temp;
            console.log("Down arr", arr);
            setState((prevState) => ({ ...prevState, [Arr]: arr }));
        }
    };

    const SaveData = () => {

        try {

            let posterId = {
                pk1: UserData.pk1,
                sk1: UserData.sk1,
                field1: UserData.filed1,
                field2: UserData.filed2,
                avatar: UserData.avatar,
            }

            let TotalSteps = {
                ServiceSteps: state.TotalStepList,
            }

            let CompanyContractor = `${ContractorData.ContractorId}::${props.ActiveKey}`;

            state.TotalStepList.map(eachstep => {
                if (eachstep.Preset) {
                    TotalSteps[eachstep.DataReceivedArrayName] = state[eachstep.DataReceivedArrayName]
                }
            })

            const key = `Data::Online::Lambda::PreSelect::${props.Data.ListType}`;

            const FormdataJSON = {
                date: currentTime,
                ContactList: state.ContactList,
                field1: `${state.CompanyNameNew} Info`,
                field2: `Pre Selected Information`,
                id: UUid,
                CompanyId: props.CompanyId,
                CompanyName: props.CompanyName,
                ContractorId: ContractorData.ContractorId,
                SearchWords: state.title,
                posterId: posterId,
                key: key,
                pk1: CompanyContractor,
                sk1: key,
                gsi1Pk2: key,
                gsi1Sk2: currentTime,
                gsi2Pk2: ContractorData.ContractorId,
                gsi2Sk2: key,
            }

            let dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON));

            let DBInput = {
                pk1: CompanyContractor,
                sk1: key,
                gsi1Pk2: key,
                gsi1Sk2: currentTime,
                gsi2Pk2: ContractorData.ContractorId,
                gsi2Sk2: key,
                dataJSON: dataJSON
            }

            console.log("PreSelect.DBInput", DBInput);
            
            console.log("PreSelect.FormdataJSON", FormdataJSON);

            API.graphql(graphqlOperation(createSvcbotDb, { input: DBInput })).then(
                (results) => {
                    console.log("createBatch Data ", results);
                    if (props.CloseDrawer) {
                        props.CloseDrawer();
                    }

                }
            );

        } catch (err) {
            console.log("SelectStepBox.err", err);
        }


    }

    const AddPerson = () => {

        //setState({
        //  ...state,
        //  NextLoadComponenet: props.NextLoadComponenet,

        //});

        SetDeawerOpen(true)

    };

    const handleInclude = (data) => {

        console.log("ContactList.data", data)

        let ContactList = state.ContactList;
        let findindex = ContactList.findIndex(x => x.key === data.key);

        if (findindex && findindex > -1) {
            ContactList[findindex] = data;
        } else {
            // if key does not exist, then add as new
            ContactList = [...state.ContactList, data];
        }

        console.log("ContactList.handleInclude", ContactList)

        setState(prevState => ({
            ...prevState,
            ContactList: ContactList
        }));
    }

    const SetValues = (data) => {

        console.log("ContactList22.data", data)

        //let ContactList = state.ContactList;
        let findindex = CategoryList.find(x => x.key === data);

        if (findindex) {
            setState(prevState => ({
                ...prevState,
                Category: findindex.Category,
             //   field1: findindex.Category,
                Selectedkey: findindex.key,
                title: `${props.Activefield1} ${findindex.Category}`
            }));
        }

    }

    console.log("PreSelect.state", state);

    return (
        <Paper className={classes.paper}>

            <Grid container spacing={1}>
                {props.Data.ListType !== "Contractor" && (
                    <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl} style={{ width: '100%' }} >
                            <InputLabel id="demo-simple-select-outlined-label">Pre Select Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                //id={`select-${Item.key}-${index}`}
                                defaultValue="Location"
                                error={state.ErrorMessage}
                                required={true}
                                disabled={state.disabled}
                                autoWidth={true}
                                value={state.Selectedkey}
                                onChange={(e) => {
                                    SetValues(e.target.value);
                                }}
                                label="Pre Select Category"
                            >
                                {CategoryList.map((o, indd) => (
                                    <MenuItem key={indd} value={o.key}>
                                        {o.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl >
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Typography variant="h4">
                        {state.title}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => AddPerson()}
                    >
                        {`Add ${state.Category}`}
                    </Button>
                </Grid>


                {state.ContactList && state.ContactList.length > 0 && (
                    <>
                        {state.ContactList.map((eachstep, indd) => (
                            <Grid item xs={12} key={"eachstepbox" + indd}>
                                <ContactBox
                                    key={"eachstep" + indd}
                                    MoveUp={e => MoveUp(indd, "ContactList")}
                                    MoveDown={e => MoveDown(indd, "ContactList")}
                                    DeleteItem={() => DeleteItem(indd, "ContactList")}
                                    CompanyId={props.CompanyId}
                                    data={eachstep}
                                    ShowButtons={true}
                                />
                            </Grid>
                        ))}
                    </>

                )}

                <Grid item xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => SaveData()}
                    >
                        Save List
                    </Button>
                </Grid>


            </Grid>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DrawerShow
                LoadComponenet={state.NewLoadComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={state.Category}
                //LoadComponenet="ItemAddPage"
                propss={{
                    Category: state.Category,
                    CloseDrawer: (e) => CloseDrawer(e),
                    handleInclude: (e) => handleInclude(e),
                    label: state.field1,
                    title: state.title,
                    CompanyId: state.CompanyIdNew,
                    CompanyName: state.CompanyNameNew,
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </Paper>
    );
}
