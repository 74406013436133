import React, { useState, useEffect } from 'react';
import {
    //Box,
    Grid,
    Container,

    //Snackbar,
    //Link,
    //TextField,
    Typography,
    //LinearProgress,

} from '@mui/material';
import { useDispatch } from 'react-redux';
//import { FindLatestDate, removeEmptyStringElements, UpdateData } from '../utils/CommonGraphql';
import { useParams } from 'react-router-dom';
//import { currentTime } from '../utils/DateTime';
//import { createBatch } from '../graphql/mutations';
//import { strengthIndicator, strengthColor } from "../utils/PasswordStrength";
import { Auth, API, graphqlOperation } from "aws-amplify";
//import Page from '../components/Page';
//import { FormSchema, CommonFormUpdated, FormPrefArray } from '../Forms/FormList';
//import StartPage from '../views/auth/LoginView';
import ItemAddPage from '../Add/ItemAddPage';
import StartPage from '../views/auth/LoginView';
import { Buffer } from 'buffer';


const AWS = require("aws-sdk");
// Initialize the Amazon Cognito credentials provider
AWS.config.region = "us-east-1"; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId
});
//const dynamodb = new AWS.DynamoDB.DocumentClient();


const LoginView = (props) => {

    const [ShowForm, SetShowForm] = useState(true);
    //const dispatch = useDispatch()

    const params = useParams();
    console.log('LandingPage.params', params);
    console.log('LandingPage.props', props);
    const initialState = {
        ShowLogin: true,
        PostData: "",
    };

    const [state, setState] = React.useState(initialState);

    useEffect(() => {

        let usernamecode = process.env.REACT_APP_SIGNINUSER;
        usernamecode = usernamecode.toLocaleLowerCase();
        let PPw = process.env.REACT_APP_PPW;
        console.log("LoginPage.SignUp.SignIn", usernamecode, PPw);
        Auth.signIn(usernamecode, PPw).then(user => {
            console.log("LandingPage.user", user);
            //   LoadDataRedux();

        }).catch(err => {
            console.log("LandingPage.err", err);
            //setState((prevState) => ({
            //    ...prevState,
            //    toDashboard: false,
            //    confirmcodemessage: `Sorry The Sign In failed. Please try again or contact ${state.ContractorName}`,
            //    step: "SignInEmail"
            //}))
        })

    }, [])

    useEffect(() => {

        let CompanyName = "";
        let CompanyId = "";
        let ContractorId = "";
        let CustomerType = "";
        let ContractorName = "";

        if (params.CompanyId) {
            CompanyName = atob(params.CompanyName);
            CompanyId = atob(params.CompanyId); 
            ContractorId = atob(params.ContractorId);
            ContractorName = atob(params.ContractorName);
            CustomerType = atob(params.CustomerType);

            setState(prevState => ({
                ...prevState,
                //   ShowLogin: true,
                CompanyName: CompanyName,
                CompanyId: CompanyId,
                ContractorId: ContractorId,
                CustomerType: CustomerType,
                ContractorName: ContractorName
            }));

        }

       
        // }
    }, [params.CompanyId])


    //const LoadPostData = async (pk, sk) => {

    //    try {


    //        let Paymentstring = `Payment::Due::${pk}`;
    //        let Amount = 0;
    //        let PostData = [];
    //        console.log('Paymentstring', Paymentstring);

    //        let getItemParams2 = {
    //            IndexName: "gsi1Pk2-gsi1Sk2-index",
    //            TableName: "svcbot_db",
    //            ScanIndexForward: false,
    //            KeyConditionExpression: "#gsi1Pk2 = :gsi1Pk2",
    //            ExpressionAttributeNames: {
    //                "#gsi1Pk2": "gsi1Pk2",
    //            },
    //            ExpressionAttributeValues: {
    //                ":gsi1Pk2": Paymentstring
    //            }
    //        };

    //        let PersonFound = await GetDBData(getItemParams2);
    //        console.log('CheckPesonBalance', JSON.stringify(PersonFound, null, 2));

    //        if (PersonFound.Count > 0) {
    //            Amount = PersonFound.Count * 3;
    //            PostData = PersonFound.Items;
    //        }

    //            setState(prevState => ({
    //                ...prevState,
    //                PostData: PostData, 
    //                Amount: Amount
    //            }));



    //    } catch (err) {
    //        console.log('LoadPref err', err);
    //    }
    //}



    console.log('LandingPage.state', state);

    return (
        <Container maxWidth="md">

            <Grid container spacing={1}>

                {state.CompanyName !== "" && state.CompanyName !== "-" ? (
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            {`Add to ${state.CompanyName}`}
                        </Typography>
                    </Grid>
                ) : null}

                {state.CompanyId && ShowForm ? (
                    <ItemAddPage
                        Category="Person"
                        CompanyId={state.CompanyId}
                        CompanyName={state.CompanyName}
                        ContractorId={state.ContractorId}
                        CustomerType={state.CustomerType}
                        ContractorName={state.ContractorName}
                        Invite={true}
                        ImageUpload={false}
                        TriggerUpdate={false}
                        CloseDrawer={() => SetShowForm(false)}
                    />
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h3" gutterBottom>
                                Thanks, you have been added to the system.
                            </Typography>
                            <Typography variant="h4" gutterBottom>
                                You can now sign in and setup your password.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <StartPage />
                        </Grid>

                    </>

                )}

            </Grid>
        </Container>
    );
};

export default LoginView;
