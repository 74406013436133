import React, { useState, useEffect } from 'react';
import {
    Grid, Typography,
    Card, CardContent, Avatar, CardHeader, Link, Button,
    Container
} from '@mui/material';

import { red } from '@mui/material/colors';
import ShowYoutube from '../components/ShowYoutube';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import EmailIcon from '@mui/icons-material/Email';

let VideoList = [
    {
        title: "First time sign-in",
        Description: "",
        VideoLink: "https://youtube.com/shorts/PbebDz59gek",
        VideoImage: ""
    },
    {
        title: "Setup Shift",
        Description: "",
        VideoLink: "https://youtu.be/o3Aqqkr-_vY",
        VideoImage: ""
    },
    {
        title: "Main Policy Setup",
        Description: "",
        VideoLink: "https://youtu.be/SlycM-Q5_5g",
        VideoImage: ""
    },
    {
        title: "Add Custom Policy",
        Description: "",
        VideoLink: "https://youtu.be/-_KJmPEpHC4",
        VideoImage: ""
    },
]

export default function SimpleCard(props) {

    const initialState = {
        SupportVideoHeading: VideoList[0].title,
        SupportVideoLink: VideoList[0].VideoLink,
        SupportVideoNumber: 0
    }
    const [state, setState] = useState(initialState);

    //useEffect(() => {
    //    try {


    //        setState(prevState => ({
    //            ...prevState,

    //        }))

    //    } catch (err) { console.log("UrgencyDetails.useEffect.err", err) }

    //}, [])

    const ChangeVideo = (title, VideoLink, indd) => {

        setState(prevState => ({
            ...prevState,
            SupportVideoHeading: title,
            SupportVideoLink: VideoLink,
            SupportVideoNumber: indd
        }))

    }

    return (
        <Container maxWidth="md">
        <Grid container spacing={3}>

            <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            
                            //subheader="Information"

                            title={<Typography variant="h3">
                                Email Support
                            </Typography>}

                            avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                <EmailIcon />
                            </Avatar>}

                        />

                        <CardContent>
                
                    <Typography variant="h3">
                        <a href="mailto:support@sninc.us">
                            support@sninc.us
                        </a>
                            </Typography>

                        </CardContent>
                    </Card>

            </Grid>

                <Grid item xs={12} >

                    <Card>
                        <CardHeader
                            
                            //subheader="Information"
                            title={<Typography variant="h3">
                                Schedule a Call / Online Meeting
                            </Typography>}

                            avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                <VideoCameraFrontIcon />
                            </Avatar>}

                        />

                        <CardContent>

                            <a href="https://calendly.com/servicewithtext/30min" rel="noreferrer" target="_blank">
                                <img alt="Call" src="https://www.servicewithtext.com/images/calendery_small.png" />
                            </a>

                        </CardContent>
                    </Card>

            </Grid>

           

            <Grid item xs={12} >

                <Card>
                        <CardHeader
                            title={<Typography variant="h3">
                                How to Videos
                            </Typography> }

                            
                        //subheader="Information"

                        avatar={<Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                            <YouTubeIcon />
                        </Avatar>}
                        
                    />

                    <CardContent>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>

                                <Typography variant="h3">
                                    {`Title: ${state.SupportVideoHeading}`}
                                </Typography>

                                    <Typography variant="h5">
                                        <a href={state.SupportVideoLink} rel="noreferrer" target="_blank">
                                            {`Click here to open "${state.SupportVideoHeading}" video in new a window`}
                                        </a>
                                    </Typography>

                                    <Typography variant="caption" gutterBottom sx={{ display: 'block' }}>
                                        Use the link above to open the video in a new window. This will allow you to follow along the System Configuration and Setup steps on the app with the video playing in a seperate window next to the app.
                                    </Typography>

                            </Grid>
                            <Grid item xs={12}>

                                <ShowYoutube
                                    YoutubeLink={state.SupportVideoLink}
                                />

                                </Grid>

                                {/*
                            <Grid item xs={12}>



                            </Grid>


                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    {VideoList.map((each, indd) => (
                                        <>
                                            <Grid item xs={12} md={4}>
                                                <Card key={"crfddssd1"}>
                                                    <CardHeader
                                                        title={each.title}
                                                        subheader={each.Description}

                                                        avatar={props.Data.avatar && props.Data.avatar !== "" ? (
                                                            <Avatar alt={props.Data.field1} src={props.Data.avatar} />
                                                        ) : (
                                                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                                                {FindCategoryIcon("Person")}
                                                            </Avatar>
                                                        )}
                                                    />

                                                    <CardContent>
                                                    show video image?
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </>
                                    ))}
                                </Grid>
                            </Grid>



                            */}

                            {VideoList.map((each, indd) => (
                                <Grid item xs={12}>

                                    <Typography variant="h5">
                                        <Button
                                            variant="text"
                                            onClick={() => ChangeVideo(each.title, each.VideoLink, indd)}
                                            startIcon={<YouTubeIcon />}
                                        >
                                            {each.title}
                                        </Button>
                                    </Typography>

                                    {each.Description !== "" ? (
                                        <Typography>
                                            {each.Description}
                                        </Typography>
                                    ) : null}

                                </Grid>

                            ))}


                        </Grid>

                    </CardContent>
                </Card>

            </Grid>
            </Grid>
        </Container>
    );
}
