//used when signing up new Owner or company/person account - need lambda

import React, { useEffect, useState } from 'react';
import {
    TextField,
    Box,
    Typography,
    //Paper,
    Grid,
    CardHeader, Card, Button, CardContent, InputLabel,
    //Backdrop,
    //CircularProgress,
    //Card,
    //FormGroup,
    //CardContent,
    //CardActions,
    Snackbar,
    //CardHeader,
    FormGroup, FormControlLabel, Checkbox,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import { NewPageId, removeEmptyStringElements } from '../utils/CommonGraphql';
import GoogleAddressAutoComplete from '../views/modules/GoogleMapPlace';
import { API } from "aws-amplify";
import ListModule from '../List/ListModule';
import DrawerShow from '../utils/DrawerModule';
import { SendEmail } from '../utils/SendEmail';
import PhoneInput from 'react-phone-input-2';
import TimeZone from '../components/TimeZone';
import { useSelector } from 'react-redux';

const UURL = NewPageId("kkey");

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

var GetDBData = (params) => {
    try {
        return new Promise((resolve, reject) => {
            dynamodb.query(params, function (err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else resolve(data); // successful response
            });
        });
    } catch (err) {
        console.log('GetDBData err', err);
    }
};

//var testMail = 'testmail@fakemail.com';
const ValidateEmail = testMail => {

    var check_email = '[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](gmail.com|msn.com|hotmail.com|yahoo.com)';
    var patt = new RegExp(check_email);
    var result = patt.test(testMail);

    console.log("AccountSetup.ValidateEmail", result)
    return result;
}

const Terms = () => {
    return (
        <Typography>
            Please accept <a href="https://www.contractorcheckin.com/terms.htm" rel="noreferrer" target="_blank">Terms and Conditions</a>
        </Typography>
     )
}

export default function RadioButtonsGroup(props) {
    //  const ContractorData = useSelector(state => state.ContractorData);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const initialState = {
        AssetList: [],
        AlarmMessage: "",
        OpenAlert: false,
        Name: "",
        Email: "",
        CompanyName: "",
        Zip: "",
        PlaceId: "",
        NoCompanyEmail: false,
        NeedCompanyEmailAvailable: false,
        Acknowledge: false,
        receiveEmail: true,
        receiveSMS: true,
        receivePhone: true,
        terms: false,
        disablemobile: false,
        receive: false,
        AccountId: "",
        WebsiteType: window.WebsiteType,
    }
    const [state, setState] = useState(initialState);
    //  const [DrawerStatus, OpenDrawer] = useState(false);


    useEffect(() => {

        if (props.SREvent && props.SREvent.ContractorData) {
            GetPolicy(props.SREvent.ContractorData.CompanyId);
        }

        if (props.SREvent && props.SREvent.RequestorSMS !== "") {
            setState(prevState => ({
                ...prevState,
                Mobile: props.SREvent.RequestorSMS,
                disablemobile: true
            }));
        }

       // checkUUr();

    }, [props.SREvent])

    useEffect(() => {

        setState(prevState => ({
            ...prevState,
            WebsiteType: window.WebsiteType
        }));

    }, [])


    //const checkUUr = async () => {

    //    const endPoint = "https://yfxflbgfcnfb7axkgtvlimexl4.appsync-api.us-east-1.amazonaws.com/graphql";
    //    const myURL = await new URL(endPoint);
    //    console.log("AccountSetup.myURL", myURL)

    //}

    const GetPolicy = async (CompanyId) => {

        let DataBack = [];
        let Acknowledge = state.Acknowledge;

        let getItemParams = {
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1) ",
            ScanIndexForward: false,
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1"
            },
            ExpressionAttributeValues: {
                ":pk1": CompanyId,
                ":sk1": "Data::Policy::"
            }
        };

        var ContractorFound = await GetDBData(getItemParams);

        console.log("AccountSetup.ContractorPolicy", ContractorFound)

        if (ContractorFound.Count > 0) {
            ContractorFound.Items.map(each => {
                DataBack.push(each.dataJSON)
            })
        } else {
            Acknowledge = true
        }

        setState(prevState => ({
            ...prevState,
            DataBack: DataBack,
            Acknowledge: Acknowledge
        }));

    }

    const TriggerEmail = () => {

        let PhotoService = [];
        let Documents = [];

        if (state.DataBack && state.DataBack.length > 0) {

            state.DataBack.map(each => {
                PhotoService = [...PhotoService, ...each.PhotoService]
                Documents = [...Documents, ...each.Documents]
            })


            var emailstring = {

                PhotoService: PhotoService,
                Documents: Documents,
                SpecialInstructions: "",
                PreSelect: [],
                title: `Policy Documents`,
                postHTML: "<h3>Please review following Policy Documents</h3>",
                postTEXT: "Please review following Policy Documents",
                PersonList: [state.Email],
                ReplyEmail: "noreply@sninc.us", //UserData.email,
                PostId: state.NewId,

                ContractorAddress: "",
                ContractorPhone: "",
                ContractorLogo: props.SREvent.ContractorData.avatar,
                ContractorName: props.SREvent.ContractorData.CompanyName,
                ContractorId: props.SREvent.ContractorData.CompanyId,
                CompanyId: props.SREvent.ContractorData.CompanyId,
                avatar: "",

                UnsubscribeLink: `https://app.contractorcheckin.com/Unsubscribe`,
                ActionButton: `https://app.contractorcheckin.com`,
                ActionButtonTitle: "Online Access",
                ActionButtonShow: false,
            };

            console.log("ItemAddPage.Invite1.email", emailstring)
            SendEmail(emailstring);
        }
    }

    const SetTimeZone = (TimeZone) => {
        try {
            if (TimeZone && TimeZone.value !== "") {
                
                setState(prevState => ({
                    ...prevState,
                    TimeZone: TimeZone
                }));
                
            }
        } catch (err) { console.log("SetAddressValues", err) }
    }

    const CreatePerson = async () => {

        //console.log("AccountSetup.CreatePerson", state)
        //console.log("AccountSetup.CreatePerson.props", props)

        try {

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let mobile = state.Mobile;
            let OldConversation = props.SREvent;
            let ContractorData = props.SREvent.ContractorData;
            let Emailaddress = state.Email;
            let ParseEmail = Emailaddress.split("@");
            // let emailName = ParseEmail[0];
            let Domain = ParseEmail[1];
            let CompanyName = state.Name;
            let FormdataJSON = {};
            let PersonExist = false;
            //let CCName = ParseEmail[1].split(".");
            //CCName.pop();

            let Acknowledge = "Denied";
            if (state.Acknowledge) {
                Acknowledge = "Accepted";
            }

            let dateToday = new Date();
            //let currentTime = new Date(dateToday).getTime();
            const dateAsString = dateToday.toString();
            let TimeZoneValue = Intl.DateTimeFormat().resolvedOptions().timeZone;

//                dateAsString.match(/\(([^\)]+)\)$/)[1];
            const timezoneOffset = (new Date()).getTimezoneOffset();
            let Offset = -1 * (timezoneOffset / 60);
            let TimeZone = {
                value: TimeZoneValue,
                offset: Offset
            }

            if (state.TimeZone && state.TimeZone.value !== "") {
                TimeZone = state.TimeZone;
                TimeZoneValue = state.TimeZone.value
            }
            

            if (state.CompanyName && state.CompanyName !== "") {
                CompanyName = state.CompanyName;  //ContName.titleCase();
            }

            if (ValidateEmail(state.Email)) {
                let CDomain = CompanyName.replace(/[^a-zA-Z0-9._]/g, "");
                Domain = CDomain.toLocaleLowerCase();
            }

           // let CompanyId = `Data::Company::Info::${Domain}::${state.PlaceId}`;
            let CompanyId = `Data::Company::Info::${state.Email}`;
            let CustomerType = "Company";
            let ContractorId = ContractorData.CompanyId;
            let ContractorName = ContractorData.CompanyName;

            if (props.OwnerSignup === true) {

                if (props.AccountTyp === "Corporate") {
                    CompanyId = `Data::Owner::${window.WebsiteType}::${Domain}::${state.PlaceId}`;
                    CustomerType = "Contractor";
                    ContractorId = `Data::Owner::${window.WebsiteType}::${Domain}::${state.PlaceId}`;
                    ContractorName = state.CompanyName;
                } else {
                    CompanyId = `Data::Owner::${window.WebsiteType}::${state.Email}`;
                    CustomerType = "Contractor";
                    ContractorId = `Data::Owner::${window.WebsiteType}::${state.Email}`;
                    ContractorName = state.CompanyName;
                }

            }  

            


            let field1 = state.Name;  // PersonName.titleCase();

            let email = Emailaddress;
            let key = `Data::Company::Resource::Person::${window.WebsiteType}::${email}`;
            let phone = mobile;
            // let CompanyName = ContractorName;
            let field2 = email;

            //check if person exist?
            let getItemParams = {
                TableName: "svcbot_db",
                KeyConditionExpression: "#pk1 = :pk1 and #sk1 = :sk1",
                ScanIndexForward: false,
                ExpressionAttributeNames: {
                    "#pk1": "pk1",
                    "#sk1": "sk1"
                },
                ExpressionAttributeValues: {
                    ":pk1": key,
                    ":sk1": key
                }
            };

            var PersonFound = await GetDBData(getItemParams);

            console.log("AccountSetup.PersonFound", PersonFound)

            if (PersonFound.Count > 0) {                
                FormdataJSON = PersonFound.Items[0].dataJSON;
                PersonExist = true;
                CompanyId = FormdataJSON.CompanyId
                OldConversation.posterId = FormdataJSON;
                OldConversation.gsi1Pk2 = `${state.WebsiteType}::${FormdataJSON.CompanyId}`;
                OldConversation.WaitForEmail = false;
                OldConversation.FormData = FormdataJSON.AllItemForms[0].FormData;

            }

            let CompanyInfo = {
                CompanyId: CompanyId,
                CustomerType: CustomerType,
                ContractorId: ContractorId,
                ContractorName: ContractorName
            }

            if (!PersonExist) {

                if (ContractorData.TimeZone && ContractorData.TimeZone.value) {
                    TimeZoneValue = ContractorData.TimeZone.value;
                }
                // let CompanyId = Contractorkey;
                let SearchWords = `${field1} ${email} ${CompanyName} ${state.PlaceId}`;
                let PostId = NewPageId("Person");
                let Privileges = {
                    "label": "Manager",
                    "value": 300
                };
                let posterId = {
                    field1: field1,
                    field2: field2,
                    CompanyId: CompanyId,
                    CompanyName: CompanyName,
                    avatar: "",
                    key: key,
                }

                let AllItemForms = [
                    {
                        "sk1": key,
                        "Category": "Person",
                        "Documents": [
                        ],
                        "field1": "Person Infomation",
                        "FormData": [
                            {
                                "Category": "Person",
                                "defaultValue": field1,
                                "Editable": false,
                                "extra": "field1",
                                "key": "ff_p6ZcrMay10",
                                "label": "Name",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 1,
                                "required": true,
                                "Searchable": true,
                                "ShowValue": field1,
                                "TableColumn": true,
                                "type": "textline",
                                "VariableInput": field1,
                                "VariableName": "name"
                            },
                            {
                                "Category": "Person",
                                "defaultValue": email,
                                "Editable": false,
                                "extra": "field2",
                                "key": "ff_p6ZcrMay11",
                                "label": "Email",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 1,
                                "required": true,
                                "Searchable": true,
                                "ShowValue": email,
                                "TableColumn": true,
                                "type": "email",
                                "VariableInput": email,
                                "VariableName": "email"
                            },
                            {
                                "Category": "Person",
                                "defaultValue": TimeZoneValue,
                                "Details": TimeZone,
                                "Editable": false,
                                "key": "ff_p6ZcrMay12",
                                "label": "Time Zone",
                                "options": [
                                ],
                                "Order": 1,
                                "required": true,
                                "Searchable": true,
                                "ShowValue": TimeZoneValue,
                                "TableColumn": true,
                                "type": "TimeZone",
                                "VariableInput": TimeZoneValue,
                                "VariableName": "TimeZone"
                            },
                            {
                                "Category": "Person",
                                "defaultValue": mobile,
                                "Editable": true,
                                "key": "ff_p6ZcrMay13",
                                "label": "Mobile/SMS",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 3,
                                "required": true,
                                "ShowValue": mobile,
                                "TableColumn": false,
                                "type": "phone",
                                "VariableInput": mobile,
                                "VariableName": "mobile"
                            },
                            {
                                "Category": "Person",
                                "defaultValue": phone,
                                "Editable": true,
                                "key": "ff_p6ZcrMay14",
                                "label": "Work Phone",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 3,
                                "required": true,
                                "ShowValue": phone,
                                "TableColumn": false,
                                "type": "phone",
                                "VariableInput": phone,
                                "VariableName": "phone"
                            },
                            {
                                "Category": "Person",
                                "defaultValue": Acknowledge,
                                "Editable": true,
                                "key": "ff_p6ZcrMay15",
                                "label": "Acknowledge Policy",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 3,
                                "required": true,
                                "ShowValue": Acknowledge,
                                "TableColumn": false,
                                "type": "checkbox",
                                "VariableInput": Acknowledge,
                                "VariableName": "Policy"
                            },
                            //{
                            //    Order: 3,
                            //    options: [],
                            //    label: "Receive Email Notification",
                            //    VariableName: "receiveEmail",
                            //    Editable: true,
                            //    TableColumn: false,
                            //    extra: "",
                            //    VariableInput: state.receiveEmail,
                            //    defaultValue: state.receiveEmail,
                            //    message: "",
                            //    type: "checkbox",
                            //    key: 3,
                            //    Category: "Person",
                            //    ListInfo: true,
                            //    required: true
                            //},
                            //{
                            //    Order: 3,
                            //    options: [],
                            //    label: "Receive Text/SMS Notification",
                            //    VariableName: "receiveSMS",
                            //    Editable: true,
                            //    TableColumn: false,
                            //    extra: "",
                            //    VariableInput: state.receiveSMS,
                            //    defaultValue: state.receiveSMS,
                            //    message: "",
                            //    type: "checkbox",
                            //    key: 3,
                            //    Category: "Person",
                            //    ListInfo: true,
                            //    required: true
                            //},
                            //{
                            //    Order: 3,
                            //    options: [],
                            //    label: "Receive Phone Notification",
                            //    VariableName: "receivePhone",
                            //    Editable: true,
                            //    TableColumn: false,
                            //    extra: "",
                            //    VariableInput: state.receivePhone,
                            //    defaultValue: state.receivePhone,
                            //    message: "",
                            //    type: "checkbox",
                            //    key: 3,
                            //    Category: "Person",
                            //    ListInfo: true,
                            //    required: true
                            //}
                        ],
                        "FormName": "Person Infomation",
                        "FormType": "Person",
                        "key": key,
                        "PhotoService": [
                        ],
                        "SearchWords": "x",
                        "ValueAdded": true
                    },

                ];

                //  console.log("AllItemForms 421", JSON.stringify(AllItemForms, null, 2));

                let FormdataJSON = {
                    AllItemForms: AllItemForms,
                    BranchId: ContractorId,
                    ContractorDataId: ContractorId,
                    BranchName: ContractorName,
                    HQId: "Developer::Account::0786",
                    HQName: "Service Notification Inc.",
                    status: "Waiting",
                    SearchWords: SearchWords,
                    AllowSMSService: true,
                    Category: "Person",
                    CustomerType: CustomerType,
                    TimeZone: TimeZone,
                    CompanyId: CompanyId,
                    CompanyName: CompanyName,
                    timezoneOffset: timezoneOffset,
                    TimeZoneValue: TimeZoneValue,
                    Location: state.Location,
                    avatar: "",
                    id: PostId,
                    key: key,
                    PersonId: key,
                    PayStatus: true,
                    Privileges: Privileges,
                    Level: 300,
                    field1: field1,
                    field2: field2,
                    ContractorId: ContractorId,
                    receiveEmail: state.receiveEmail,
                    receiveSMS: state.receiveSMS,
                    receivePhone: state.receivePhone,
                    date: dateAsString,
                    posterId: posterId,
                    PhotoService: [],
                    Documents: [],
                    phone: phone,
                    mobile: mobile,
                    email: email,
                    pk1: key,
                    sk1: key,
                    gsi1Pk2: `phone::${WebsiteType}::${mobile}`,
                    gsi1Sk2: "",
                    gsi2Pk2: CompanyId,
                    gsi2Sk2: key,
                };

                let PersonInfo = {

                    BranchId: ContractorId,
                    BranchName: ContractorName,
                    HQId: "Developer::Account::0786",
                    HQName: "Service Notification Inc.",
                    Category: "Person",
                    CustomerType: CustomerType,
                    AllowSMSService: true,
                    TimeZone: TimeZone,
                    PersonId: key,
                    CompanyId: CompanyId,
                    CompanyName: CompanyName,
                    avatar: "",
                    id: PostId,
                    key: key,
                    Privileges: Privileges,
                    Level: 300,
                    field1: field1,
                    field2: field2,
                    ContractorId: ContractorId,
                    receiveEmail: state.receiveEmail,
                    receiveSMS: state.receiveSMS,
                    receivePhone: state.receivePhone,
                    date: dateAsString,
                    posterId: posterId,
                    PhotoService: [],
                    Documents: [],
                    phone: phone,
                    mobile: mobile,
                    email: email,
                    pk1: key,
                    sk1: key,
                    gsi1Pk2: `phone::${WebsiteType}::${mobile}`,
                    gsi1Sk2: "",
                    gsi2Pk2: CompanyId,
                    gsi2Sk2: key,
                };

                console.log("AccountSetup.AddPerson", FormdataJSON);

                
                //setState(prevState => ({
                //    ...prevState,
                //    DoneDone: true,
                //}))

                //console.log("AccountSetup.key", SelectedDataReturn)

                
                //adding person
                let DBInput = {
                    pk1: key,
                    sk1: key,
                    gsi1Pk2: `phone::${WebsiteType}::${mobile}`,
                    gsi1Sk2: key,
                    gsi2Pk2: CompanyId,
                    gsi2Sk2: key,
                    dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
                };

                // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
                let PosttoDB = {
                    MutationType: "createSvcbotDb",
                    DBData: DBInput
                };

                // let Messagereceived = `Account created, please refresh the Person list on the app`;
                // MessageBackSMS(TwilioSMSData.To, TwilioSMSData.From, Messagereceived);

                if (CustomerType !== "Contractor") {
                    CreatePersonAccunts(FormdataJSON)
                }

                // Post to DB
                TriggerLambda(PosttoDB, "CreatePerson");

                ZapPerson(FormdataJSON)
            }

            CheckOnCompany(FormdataJSON, CompanyInfo);

            if (props.PersonInformation) {
                props.PersonInformation(FormdataJSON);
            }

            if (props.SelectedDataReturn) {
                props.SelectedDataReturn(OldConversation)
            }

            if (props.handleNext) {
                props.handleNext();
            }

            if (state.receive) {
                TriggerEmail();
            }

        } catch (err) { console.log("put.err", err) }


    }

    const ZapPerson = async (event) => {

        try {

            let getItemParams2 = {
                IndexName: "gsi1Pk2-gsi1Sk2-index",
                TableName: "svcbot_db",
                Limit: 10,
                ScanIndexForward: false,
                KeyConditionExpression: "#gsi1Pk2 = :gsi1Pk2 and #gsi1Sk2 = :gsi1Sk2",
                ExpressionAttributeNames: {
                    "#gsi1Pk2": "gsi1Pk2",
                    "#gsi1Sk2": "gsi1Sk2",
                },
                ExpressionAttributeValues: {
                    ":gsi1Pk2": event.ContractorId,
                    ":gsi1Sk2": "Zapier::addperson::Subscribe"
                }
            };
            // console.log("SelectedArray5.getItemParams2 ---", getItemParams2);
            let ZapierCheck = await GetDBData(getItemParams2);

            console.log("MessageSentList", JSON.stringify(ZapierCheck, null, 2))

            if (ZapierCheck.Count > 0
                && event.CustomerType === "Company") {

                let ZapInfo = ZapierCheck.Items[0];
                let ZapdataJSON = ZapInfo.dataJSON;

                const workordercode = {                    
                    Category: "Person",
                    CompanyId: event.CompanyId,
                    CompanyName: event.CompanyName,
                    AccountId: state.AccountId,
                    Address: state.Address,
                    PersonName: event.field1,  
                    PersonPhone: event.phone,
                    PersonMobile: event.mobile,
                    PersonEmail: event.email,  
                    date: event.date,
                    TimeZoneName: event.TimeZoneValue,
                };
                                
                let ZapData = {
                    ZapTrigger: ZapdataJSON,
                    ZapData: workordercode
                }
               
                TriggerZapSendData(ZapData);

                console.log('Zapier Data', JSON.stringify(ZapData, null, 2));
            }

        } catch (err) {
            console.log('Zapier err', JSON.stringify(err, null, 2));
        }


    }

    const ZapCompany = async (event) => {

        try {

            let getItemParams2 = {
                IndexName: "gsi1Pk2-gsi1Sk2-index",
                TableName: "svcbot_db",
                Limit: 10,
                ScanIndexForward: false,
                KeyConditionExpression: "#gsi1Pk2 = :gsi1Pk2 and #gsi1Sk2 = :gsi1Sk2",
                ExpressionAttributeNames: {
                    "#gsi1Pk2": "gsi1Pk2",
                    "#gsi1Sk2": "gsi1Sk2",
                },
                ExpressionAttributeValues: {
                    ":gsi1Pk2": event.ContractorId,
                    ":gsi1Sk2": "Zapier::addcompany::Subscribe"
                }
            };
            // console.log("SelectedArray5.getItemParams2 ---", getItemParams2);
            let ZapierCheck = await GetDBData(getItemParams2);

            console.log("MessageSentList", JSON.stringify(ZapierCheck, null, 2))

            if (ZapierCheck.Count > 0
                && event.CustomerType === "Company") {

                let ZapInfo = ZapierCheck.Items[0];
                let ZapdataJSON = ZapInfo.dataJSON;

                const workordercode = {
                    Category: "Company",
                    CompanyId: event.CompanyId,
                    CompanyName: event.CompanyName,
                    AccountId: state.AccountId,
                    Address: state.Address,
                    date: event.date,
                    TimeZoneName: event.TimeZoneValue,
                };
                                
                let ZapData = {
                    ZapTrigger: ZapdataJSON,
                    ZapData: workordercode
                }

                TriggerZapSendData(ZapData);

                console.log('Zapier Data', JSON.stringify(ZapData, null, 2));
            }

        } catch (err) {
            console.log('Zapier err', JSON.stringify(err, null, 2));
        }


    }

    const CheckOnCompany = async (FormdataJSON, CompanyInfo) => {

        let Companyresult = await CheckCompanyExist("Developer::Account::0786", CompanyInfo.CompanyId);

        if (Companyresult && Companyresult.exist) {

            if (FormdataJSON.CustomerType !== "Contractor") {

                //check if it exist under contactor
                let ExistUnder = await CheckCompanyExist(CompanyInfo.ContractorId, `Under::${CompanyInfo.CompanyId}`);
                console.log("AccountSetup.CheckCompanyExist.ExistUnder", ExistUnder);

                if (ExistUnder && ExistUnder.exist) {

                    setState(prevState => ({
                        ...prevState,
                        DoneDone: true,
                    }))

                } else {

                    CreateUnderOwner(CompanyInfo, Companyresult.CompanyData)
                }
            } else {

                setState(prevState => ({
                    ...prevState,
                    DoneDone: true,
                }))

            }

        } else {
           
                CreateCompany(FormdataJSON)

        }


    }

    const CheckCompanyExist = async (pk1, sk1) => {

        let getItemParams = {
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 and #sk1 = :sk1",
            ScanIndexForward: false,
            ExpressionAttributeNames: {
                "#pk1": "pk1",
                "#sk1": "sk1"
            },
            ExpressionAttributeValues: {
                ":pk1": pk1,
                ":sk1": sk1
            }
        };

        var ContractorFound = await GetDBData(getItemParams);

        if (ContractorFound.Count > 0) {
            let FormdataJSON = ContractorFound.Items[0].dataJSON;

            console.log("AccountSetup.CheckCompanyExist", FormdataJSON);

            return {
                exist: true,
                CompanyData: FormdataJSON
            };
            //create under owner
            //  CreateUnderOwner(data, ContractorFound)
        } else {
            //create company
            return {
                exist: false,
                CompanyData: ""
            };

        }
    }

    const CreateUnderOwner = (data, CompanyData) => {
        try {

            let dataJSON = {
                ...CompanyData,
                pk1: data.ContractorId,
                sk1: data.CompanyId,
                key: data.CompanyId,
                gsi2Pk2: data.CompanyId,
                gsi2Sk2: data.ContractorId,
            }

            let DBInput = {
                pk1: data.ContractorId,
                sk1: `Under::` + data.CompanyId,
                gsi1Pk2: data.CompanyId,
                gsi1Sk2: data.ContractorId,
                gsi2Pk2: data.CompanyId,
                gsi2Sk2: data.ContractorId,
                dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
            };

            console.log("AccountSetup.CreateUnderOwner", dataJSON);
            console.log("AccountSetup.CreateUnderOwner.DBInput", DBInput);

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let PosttoDB = {
                MutationType: "createSvcbotDb",
                DBData: DBInput
            };

            // Post to DB
            TriggerLambda(PosttoDB, "CreateUnderOwner");

            return true;

        } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }

    }

    const CreatePersonAccunts = (data, CompanyInfo) => {
        try {
                        
            let InputArray = [];

            //this shows up on company personnel page
            let dataJSON = {
                AssetPkSk: {
                    pk1: "Developer::Account::0786",
                    sk1: CompanyInfo.ContractorId
                },
                pk1: `Account::` + data.sk1,
                sk1: CompanyInfo.ContractorId,                   
                gsi1Pk2: data.sk1,
                gsi1Sk2: `Account::` + CompanyInfo.ContractorId,
                gsi2Pk2: CompanyInfo.CompanyId,
                gsi2Sk2: `Account::` + CompanyInfo.ContractorId,
                CustomerType: data.CustomerType,
                CompanyName: data.CompanyName,
                key: CompanyInfo.ContractorId,
                PersonId: data.pk1,
                ContractorId: CompanyInfo.ContractorId,
                CompanyId: data.CompanyId,
                field1: CompanyInfo.ContractorName,
                field2: "-",
                Category: "ContractorAccount",   // Company's person signed up with contractor - list contractors at sign-in
            }

            

            let DBInput = {
                pk1: `Account::` + data.sk1,
                sk1: CompanyInfo.ContractorId,
                gsi1Pk2: data.sk1,
                gsi1Sk2: `Account::` + CompanyInfo.ContractorId,
                gsi2Pk2: CompanyInfo.CompanyId,
                gsi2Sk2: `Account::` + CompanyInfo.ContractorId,
                dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
            };

            InputArray.push(DBInput);

            //this shows up in contractor page
            let PersonJson = {
                AssetPkSk: {
                    pk1: data.pk1,
                    sk1: data.sk1
                },
                PersonId: data.pk1,
                CompanyId: data.CompanyId,
                field2: data.field2,
                field1: data.field1,
                CustomerType: data.CustomerType,
                CompanyName: data.CompanyName,
                pk1: `Account::` + data.sk1,
                sk1: data.sk1,
                gsi1Pk2: data.CompanyId,
                gsi1Sk2: CompanyInfo.ContractorId,
                gsi2Pk2: CompanyInfo.ContractorId,
                gsi2Sk2: `Account::` + data.CompanyId,
                Category: "CompanyPersonnel",  // company personal in Contractor system - show only when contractor is looking
            }

            let PersonIdInput = {
                pk1: `Account::` + data.sk1,
                sk1: data.sk1,
                gsi1Pk2: data.CompanyId,
                gsi1Sk2: CompanyInfo.ContractorId,
                gsi2Pk2: CompanyInfo.ContractorId,
                gsi2Sk2: `Account::` + data.CompanyId,
                dataJSON: JSON.stringify(removeEmptyStringElements(PersonJson))
            };

            InputArray.push(PersonIdInput);

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let PosttoDB = {
                MutationType: "createBatch",
                DBData: InputArray
            };

            // Post to DB
            TriggerLambda(PosttoDB, "CreateUnderOwner");

        } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }

    }

    const CreateCompany = async (data, OldConversation) => {
        try {

            let SearchWords = `${state.CompanyName} ${state.PlaceId} ${window.WebsiteType}`;
            let PostId = NewPageId("3933kiqPPwikskkLLA");

            let AllItemForms = [
                {
                    "sk1": data.CompanyId,
                    "Category": "Company",
                    "Documents": [
                    ],
                    "FormData": [
                        {
                            "Category": "Company",
                            "defaultValue": data.CompanyName,
                            "Editable": false,
                            "extra": "field1",
                            "key": 100,
                            "label": "Company Name",
                            "options": [
                            ],
                            "Order": 1,
                            "required": true,
                            "Searchable": true,
                            "TableColumn": true,
                            "type": "textline",
                            "VariableInput": data.CompanyName,
                            "VariableName": "CompanyName"
                        },
                        {
                            "Category": "Company",
                            "defaultValue": "",
                            "Editable": true,
                            "extra": "field2",
                            "key": 101,
                            "label": "Business Type",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "textline",
                            "VariableInput": "",
                            "VariableName": "BusinessType"
                        },
                        {
                            "Category": "Company",
                            "defaultValue": "",
                            "Editable": true,
                            "extra": "",
                            "key": 101,
                            "label": "Account Id (Optional from Your Database)",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "textline",
                            "VariableInput": state.AccountId,
                            "VariableName": "AccountId"
                        },

                        {
                            "Category": "Company",
                            "defaultValue": "",
                            "Editable": true,
                            "extra": "",
                            "key": 101112,
                            "label": "Address",
                            "options": [
                            ],
                            "Order": 1,
                            "required": false,
                            "TableColumn": false,
                            "type": "GoogleAddressAutoComplete",
                            "VariableInput": state.Location,
                            "VariableName": "Location"
                        },

                    ],
                    "FormName": "Company Infomation",
                    "FormType": "Company",
                    "key": data.CompanyId,
                    "PhotoService": [
                    ],
                    "ValueAdded": true
                }
            ];
            
            let Monthly = 0;
            let PerCall = 0;
            let MaxExceedMonthly = 0;
            let ResponseStartTime = new Date().getTime();
            let GSI = {};

            if (props.OwnerSignup === true) {
                switch (window.WebsiteType) {
                    case "ContractorCheckIn":
                        Monthly = 0;
                        PerCall = 3;
                        MaxExceedMonthly = 19;
                        break;
                    case "ServiceWithText":
                        Monthly = 29;
                        PerCall = 5;
                        MaxExceedMonthly = 0;
                        break;
                    case "NotificationBridge":
                        Monthly = 29;
                        PerCall = 5;
                        MaxExceedMonthly = 0;
                        break;
                    default:
                }

                GSI = {
                    gsi1Pk2: "Data::Owner::Accounts",
                    gsi1Sk2: ResponseStartTime,
                    gsi2Pk2: "Active::Owners",
                    gsi2Sk2: ResponseStartTime,
                }

            }

            let FormdataJSON = {
                ...data,
                AllItemForms: AllItemForms,
                status: "Active",
                AccountId: state.AccountId,
                Monthly: Monthly,
                PerCall: PerCall,
                MaxExceedMonthly: MaxExceedMonthly,
                SearchWords: SearchWords,
                AllowSMSService: true,
                CompanyName: data.CompanyName,
                Category: "Company",
                CustomerType: data.CustomerType,
                id: PostId,
                key: data.CompanyId,
                field1: data.CompanyName,
                field2: state.Address,
                WebsiteType: window.WebsiteType,
                pk1: "Developer::Account::0786",
                sk1: data.CompanyId,
                CompanyId: data.CompanyId,
                ...GSI                
            };

            console.log("AccountSetup.CreateCompany", FormdataJSON);

            let DBInput = {
                pk1: "Developer::Account::0786",
                sk1: data.CompanyId,
                ...GSI,
                dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
            };

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let PosttoDB = {
                MutationType: "createpksk",
                DBData: DBInput
            };

            // let Messagereceived = `Account created, please refresh the Person list on the app`;
            // MessageBackSMS(TwilioSMSData.To, TwilioSMSData.From, Messagereceived);

            // Post to DB
            TriggerLambda(PosttoDB, "CreateCompany");

            ZapCompany(FormdataJSON);

            

            if (data.CustomerType !== "Contractor") {
                await CreateUnderOwner(data, FormdataJSON)
            }

            setState(prevState => ({
                ...prevState,
                DoneDone: true,
            }))


        } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }

    }
    
    const TriggerZapSendData = (event) => {
        console.log("NewServiceCall.ZapSendData", event);

        API.post("ZapSendData", "", {
            body: { Records: event }
        });
    }

    const TriggerLambda = (event, from) => {
        console.log("NewServiceCall.TriggerLambda", from, event);

        API.post("PostToDB", "", {
            body: { Records: event }
        });
    }

    const SetValues = (data, name) => {

        if (name === "Email" && data !== "") {
            data = data.toLowerCase();
        }

        if (name === "Zip" && data !== "") {
            data = data.replace(/[^a-zA-Z0-9._]/g, "");
        }

        if (name === "Location" && data.place_id !== "") {
            // data = data.replace(/[^a-zA-Z0-9._]/g, "");
            setState(prevState => ({
                ...prevState,
                PlaceId: data.place_id,
                Address: data.address
            }));
        }


        setState(prevState => ({
            ...prevState,
            [name]: data
        }));

    }

    const handleNext = () => {

        const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

        let AlarmMessage = "";
        let OpenAlert = false;
        let NeedCompanyEmailAvailable = false;

        if (state.Name === "") {
            OpenAlert = true;
            AlarmMessage += " * Please enter your Name ";
        }

        if (state.terms === false) {
            OpenAlert = true;
            AlarmMessage += " * Please review and accept Terms & Conditions ";
        }

        if (!state.Acknowledge) {
            OpenAlert = true;
            AlarmMessage += " * Please Acknowledge Policy Documents ";
        }

        //if (state.CompanyName === "") {
        //    OpenAlert = true;
        //    AlarmMessage += " * Please enter your Company Name ";
        //}

        if (state.Email === "") {
            OpenAlert = true;
            AlarmMessage += " * Please enter Email ";
        }

        if (!emailRegexp.test(state.Email)) {
            OpenAlert = true;
            AlarmMessage += " * Please enter Company Email ";
        }

        //if (ValidateEmail(state.Email)) {
        //    if (!state.NoCompanyEmail) {
        //        OpenAlert = true;
        //        AlarmMessage += " * Gmail, MSN, HotMail, Yahoo not preferred. Please use company email, or mark the check box ";
        //        NeedCompanyEmailAvailable = true;
        //    }
        //}

        //if (state.PlaceId === "") {
        //    OpenAlert = true;
        //    AlarmMessage += " * Please enter your Company's Local office Address ";
        //}

        if (OpenAlert) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage,
                OpenAlert: OpenAlert,
              //  NeedCompanyEmailAvailable: NeedCompanyEmailAvailable
            }));

        } else {

            CheckIfPersonExist();

        }

    }

    const CheckIfPersonExist = async () => {

        let key = `Data::Company::Resource::Person::${WebsiteType}::${state.Email}`;
        //let AlarmMessage = "";
        //let OpenAlert = false;

        let getItemParams = {
            TableName: "svcbot_db",
            KeyConditionExpression: "#pk1 = :pk1 ",
            ScanIndexForward: false,
            ExpressionAttributeNames: {
                "#pk1": "pk1"
            },
            ExpressionAttributeValues: {
                ":pk1": key
            }
        };

        var PersonFound = await GetDBData(getItemParams);

        console.log("AccountSetup.ContractorPolicy", PersonFound)

        //if person exist??
        if (PersonFound.Count > 0) {
            
            let FormdataJSON = PersonFound.Items[0].dataJSON;

            //sign up for owner
            if (props.OwnerSignup === true) {

                //check if peson already owner?
                if (FormdataJSON.CompanyId.includes(`::Owner::${window.WebsiteType}`)) {
                    setState(prevState => ({
                        ...prevState,
                        AlarmMessage: "Your account already exist in the system. Please use the Login link to access your account",
                        OpenAlert: true,
                    }));

                } else {
                    //else create account
                    CreatePerson();

                }
                
            } else {
                //if person exist and not owner signup, check on company
                CheckOnCompany(FormdataJSON);
            }

            
        } else {
            //if person does not exist
            CreatePerson();
        }

    }

    const handleClose = (event, reason) => {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const AddBox = (add) => {
        console.log("DocumentPage.AddBox", add);
        setState(prevState => ({
            ...prevState,
            DrawerTitle: "Document Details",
            // SetupSubscription: true,
            DetailComponent: "ShowDetailsText",
            ItemData: add,
            DrawerKey: NewPageId("DrawerKey"),
            NewCategory: "ShowDetails",
        }))

        OpenDrawer(true);
        //}
    }

    const CloseDrawer = (c) => {
        //    GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }


    console.log("AccountSetup.state", state)
    console.log("AccountSetup.props", props)

    return (
        <>
            <Grid container spacing={1}>

                <Grid item xs={12} >
                    <TextField
                        label="Name"
                        name="Name"
                        value={state.Name || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Name")}
                    />

                </Grid>
                <Grid item xs={12} >
                    <TextField
                        label="Email"
                        name="Email"
                        value={state.Email || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "Email")}
                    />
                </Grid>

                {
                    state.NeedCompanyEmailAvailable ? (
                    <Grid item xs={12} >
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox onChange={(e) => SetValues(e.target.checked, "NoCompanyEmail")} />}
                                label="Company Email not availabe" />
                        </FormGroup>

                    </Grid>
                    ) : null
                }

                <Grid item xs={12} >

                    <InputLabel id="phone1121">Mobile</InputLabel>
                    <PhoneInput
                        country={'us'}
                        key="phonekeyysak"
                        id="phonekeyysassak"
                        //   dropdownClass={classes.countryList}
                        disabled={state.disablemobile}
                        label="Mobile"
                        inputProps={{
                            name: state.Mobile,
                            label: "Mobile",
                            required: true,
                            style: { width: '100%' },
                            //value: Item.VariableInput || ""
                            //error: ErrorMessage
                        }}
                        isValid={(value, country) => {
                            if (value.match(/12345/)) {
                                return 'Invalid value: ' + value + ', ' + country.name;
                            } else if (value.match(/1234/)) {
                                return false;
                            } else {
                                return true;
                            }
                        }}
                        value={state.Mobile}
                        onChange={(e) => SetValues('+' + e, "Mobile")}

                    />

                </Grid>

                {/*  
                <Grid item xs={12} >
                    <TextField
                        label="Company Name"
                        name="CompanyName"
                        value={state.CompanyName || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "CompanyName")}
                    />

                </Grid>

                
                <Grid item xs={12} >
                    <TextField
                        label="CRM or Work Order System Account Id (optional)"
                        name="AccountId"
                        value={state.AccountId || ""}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => SetValues(e.target.value, "AccountId")}
                    />
                </Grid>
               
                
                <Grid item xs={12} >
                    <InputLabel >Address</InputLabel>
                    <GoogleAddressAutoComplete
                        key={UURL}
                        defaultValue={state.Address || ""}
                       // error={ErrorMessage}
                        autocomplete="off"
                        disabled={state.disabled}
                        label="Address"
                        AddressObj={(e) => SetValues(e, "Location")}
                    />
                </Grid>
                */}

                {/* 
                <Grid item xs={12} >
                    <InputLabel >Time Zone</InputLabel>

                    <Box
                        style={{ zIndex: 'tooltip' }}
                    >
                        <TimeZone                            
                            defaultValue={state.TimeZone && state.TimeZone.value}                            
                            autocomplete="off"
                            disabled={state.disabled}
                            label="Time Zone"
                            TimeZoneValue={(e) => SetTimeZone(e)}
                        />
                    </Box>
                </Grid>
                */}

                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox onChange={(e) => SetValues(e.target.checked, "terms")} />}
                            label={<Terms />}
                        />
                    </FormGroup>
                </Grid>
                
                {/* 
                <Grid item xs={12} >
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(e) => SetValues(e.target.checked, "receiveEmail")}
                                checked={state.receiveEmail}
                            />} label="Opt in - Email" />
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(e) => SetValues(e.target.checked, "receiveSMS")}
                                checked={state.receiveSMS}
                            />} label="Opt in - Text Messages" />
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(e) => SetValues(e.target.checked, "receivePhone")}
                                checked={state.receivePhone}
                            />} label="Opt in - Phone Calls" />
                    </FormGroup>
                </Grid>
                */}

                {state.DataBack && state.DataBack.length > 0 ? (
                <Grid item xs={12} >

                    <Card>
                        <CardHeader
                            title="Policy Document List"
                            subheader="Please acknowledge"

                        />
                        <CardContent>
                            <Grid container spacing={1}>

                                        <Grid item xs={12} >
                                            <ListModule
                                                title="Policy Documents"
                                                //  ShowDetailLink={false}
                                                AllowDelete={false}
                                                ShowDetailLink={true}
                                                DetailLink={(e) => AddBox(e)}
                                                disabled={false}
                                                DataBack={state.DataBack}
                                            />
                                        </Grid>

                                        <Grid item xs={12} >
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        value={state.Acknowledge}
                                                        onChange={(e) => SetValues(e.target.checked, "Acknowledge")} />} label="Acknowledge" />
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        value={state.receive}
                                                        onChange={(e) => SetValues(e.target.checked, "receive")} />} label="Receive Policy by Email" />
                                            </FormGroup>
                                        </Grid>
                                    
                            </Grid>
                        </CardContent>
                    </Card>

                </Grid>
                ) : null}

                <Grid item xs={12}>
                    <div>
                        <div>
                            <Button
                                disabled={props.activeStep === 0}
                                onClick={() => props.handleBack()}
                            // className={classes.button}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNext()}
                            //  className={classes.button}
                            >
                                {'Next'}
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

            <DrawerShow
                LoadComponenet="ShowDetailsText"
                CloseDrawer={(e) => CloseDrawer(e)}
                Category="Policy"
                DrawerTitle="Policy Document"
                propss={{
                    Category: "Policy",
                    CompanyId: state.CompanyId,
                    CompanyName: state.CompanyName,
                    CloseDrawer: (e) => CloseDrawer(e),
                    //  TriggerUpdate: true,
                    //  SetupSubscription: state.SetupSubscription,
                    Data: state.ItemData,
                    // SubData: state.SubData,
                    id: state.DrawerKey,
                    // AddItem: () => AddItem("ItemAddPage", "Person")
                }}
                key={state.DrawerKey}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </>

    );
}
