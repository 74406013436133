import React, { useState, useEffect } from 'react';
//import { Box, Container,  } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Page from '../components/Page';

import RenderModule from '../utils/RenderModule';
import DrawerShow from '../utils/DrawerModule';
import { NewPageId, getAutoCompletedata, DataBaseKey } from '../utils/CommonGraphql';


import {
    ListItem,
    Card, CardHeader, IconButton, CardContent,
    Divider, Box, Tooltip, Hidden, Button,
    ListItemText, ListItemAvatar, Avatar,
    Backdrop, CircularProgress, Grid
} from "@mui/material";
import Skeleton from '../components/Skeleton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';


function useQuery() {
    return new URLSearchParams(useLocation().search);
}


const ListItems = (props) => {

    // let query = useQuery();
    let navigate = useNavigate();
    const dispatch = useDispatch();


    const params = useParams();

    //get Redux
    const UserData = useSelector(state => state.UserData)
    const ContractorData = useSelector(state => state.ContractorData);
    const TriggerUpdate = useSelector(state => state.TriggerUpdate)
    const cid = useSelector(state => state.cid);
    const Global = useSelector(state => state.Global);

    //if window is mobile or regular
    const matches = useMediaQuery('(min-width:600px)');
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        Blocks: [],
        SearchWords: "",
        ShowDetails: false,
        anchor: "left",
        DetailLink: "",
        BoxWidth: 12,
        //ListType: "ListModule",
        ListType: "ListModule",
        CompanyId: cid.CompanyId,
        pk1: cid.CompanyId,
        propss: {},
        key: NewPageId("win"),
        DataBack: [],
        DetailComponent: "ShowDetailsText"
    }
    const [state, setState] = useState(initialState);



    useEffect(() => {


        console.log('ListItems.props', props);
        console.log('ListItems.params', params);


        let Category = "Company";
        let Heading = "";
        let DetailComponent = "CompanyPage";
        let DetailLink = "company";
        let ListType = "ListModule";
        let CompanyId = ContractorData.ContractorId;
        let CompanyName = ContractorData.CompanyName;
        let CustomerType = "Company"
        let pk1 = cid.CompanyId;
        let BoxWidth = state.BoxWidth;
        let AssetType = "";

        if (props.BoxWidth && props.BoxWidth > 0) {
            BoxWidth = props.BoxWidth;
        }

        if (params.Category) {
            Category = params.Category;
        }

        if (props.Category) {

            Category = props.Category;
        }

        if (params.DetailComponent) {
            DetailComponent = params.DetailComponent
        }

        if (props.DetailComponent) {
            DetailComponent = props.DetailComponent
        }

        if (params.ListType) {
            ListType = params.ListType
        }

        if (props.ListType) {
            ListType = props.ListType
        }

        if (params.CompanyId) {
            CompanyId = params.CompanyId;
            CompanyName = params.CompanyName
            pk1 = params.CompanyId
        }

        if (props.CompanyId) {
            CompanyId = props.CompanyId;
            CompanyName = props.CompanyName;
            pk1 = props.CompanyId
        }

        if (props.Heading) {
            Heading = props.Heading;
        } else {
            Heading = Category;
        }

        //change the page that shows all the details
        //if (props.DetailLink) {
        //    DetailLink = props.DetailLink;
        //}

        if (props.AssetType) {
            AssetType = props.AssetType;
        }

        if (UserData.CustomerType === "Contractor" && Category === "Company") {
            pk1 = ContractorData.ContractorId;
            //setState(prevState => ({
            //  ...prevState,
            //  pk1: ContractorData.ContractorId,
            //}));
        }

        if (UserData.CustomerType === "Contractor" && CompanyId === ContractorData.ContractorId) {
            pk1 = ContractorData.ContractorId;
            CustomerType = "Contractor";
        }

        const UUid = NewPageId(Category);
        setState(prevState => ({
            ...prevState,
            Category: Category,
            UUid: UUid,
            pk1: pk1,
          //  DetailLink: DetailLink,
            Heading: Heading,
            BoxWidth: BoxWidth,
            ListType: ListType,
            CompanyId: CompanyId,
            CompanyName: CompanyName,
            CustomerType: CustomerType,
            DetailComponent: DetailComponent,
            AssetType: AssetType
        }));

        console.log("ListItems.DB", Category, pk1);

        //if (Category === "AccountList") {
        //    GetDatafromDB(Category, UserData.key, AssetType);
        //} else {
        //    GetDatafromDB(Category, pk1, AssetType);
        //}

        switch (Category) {
            case "AccountList":
                GetDatafromDB(Category, UserData.key, AssetType);
                break;
            case "CompanyPersonnel":
                GetDatafromDB(Category, ContractorData.ContractorId, AssetType, CompanyId);
                break;
            default:
                GetDatafromDB(Category, pk1, AssetType);
        }

    }, [])

    const ReloadData = () => {

        switch (state.Category) {
            case "AccountList":
                GetDatafromDB(state.Category, UserData.key, state.AssetType);
                break;
            case "CompanyPersonnel":
                GetDatafromDB(state.Category, ContractorData.ContractorId, state.AssetType, state.CompanyId);
                break;
            default:
                GetDatafromDB(state.Category, state.pk1, state.AssetType);
        }

    }


    useEffect(() => {

        if (TriggerUpdate === true) {
        
            ReloadData();

            dispatch({ type: 'TRIGGER_UPDATE', payload: false });
        }

    }, [TriggerUpdate]);

    useEffect(() => {

        if (props.PersonalReload === true) {
          
            ReloadData();

            if (props.PutinCommonState) {
                props.PutinCommonState({ itemvalue: false, itemname: "PersonalReload" });
            }
        }

    }, [props.PersonalReload]);

    

    //const TriggerReloadonSubscription = (SubscriptionData) => {
    // PersonalReload
    //    try {
    //         console.warn("ListItems.state.DataBack", state.DataBack)
    //        let DataBack = [];
    //        if (state.DataBack && state.DataBack.length > 0) {
    //            DataBack = state.DataBack;
    //        }
    //        let DataBaseKeycat = DataBaseKey(state.Category, state.pk1);
    //        let Subscriptiondata = SubscriptionData.data.onCreateSvcbotDb;
    //        let NewSk1 = DataBaseKeycat.sk;
    //        let NewPk1 = DataBaseKeycat.pk;
    //        if (Subscriptiondata.sk1 && Subscriptiondata.sk1.includes(NewSk1)) {
    //            console.log("ListItems.subscriptiuon.sk1", NewSk1)
    //            if (Subscriptiondata.gsi1Pk2 === NewPk1 ||
    //                Subscriptiondata.gsi2Pk2 === NewPk1 ||
    //                Subscriptiondata.pk1 === NewPk1
    //            ) {
    //                console.log("ListItems.subscriptiuon.pk1", NewPk1)
    //                let NewdataJSON = JSON.parse(Subscriptiondata.dataJSON);

    //                setState(prevState => ({
    //                    ...prevState,
    //                    DataBack: [NewdataJSON, ...DataBack]
    //                }))
    //            }
    //        }

    //    } catch (err) {
    //        console.warn("ListItems.TriggerReloadonSubscription", err)
    //    }
    //}

    const GetDatafromDB = async (Category, pk1, AssetType, Id = "", first = 100) => {
        SetLoading(true);

        let bbatch = [];
        if (Category === "GlobalAccounts" && Global.length > 0) {
            Global.map(each => {
                bbatch.push({
                    pk1: each.gsi2Pk2,
                    sk1: each.gsi2Sk2
                })
            })
        }

        const DataString = {
            Category: Category,
            FindArray: [
                {
                    ArrayName: "DataBack",
                    DuplicateArray: "RawData",
                    ArraySearchString: "",
                    Order: "descending",
                    Sort: true,
                    Sortby: "field1"
                }
            ]
        };

        //DataString, cid, AssetType = "", Id = "", first=100, batch=[]

        let NewData = await getAutoCompletedata(DataString, pk1, AssetType, Id, first, bbatch);

        setState(prevState => ({
            ...prevState,
            ...NewData
        }))

        SetLoading(false);
        console.log("ListItems.NewData", loading, NewData);
    }

    // Additional Information Box
    const AddBox = (add, DetailComponent) => {
        console.log("ShowDetails.AddBox", DetailComponent, add);

        if (DetailComponent == null || DetailComponent === "") {
            DetailComponent = state.DetailComponent;
        }

        let NewKey = NewPageId("list");
        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            Category: state.Category,
            key: add.key || NewKey,
            ShowDetails: false,
            DetailsProps: {
                title: add.field1,
                Category: state.Category,
                ShowEditButton: false,
                PreviewEdit: false,
                Data: add
            },
        }))

        OpenDrawer(true);
    }


    const CompanyData = (data) => {
        console.log("data", data);
        GetDatafromDB(state.Category, data.pk1, state.AssetType);
    }

    const AddItem = (data) => {
        console.log("ListItems.AddItem", data);
        let DetailComponent = "ItemAddPage";

        if (data === "ESC") {
            DetailComponent = "ESCSetupPage"
        }

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            DrawerKey: NewPageId("DrawerKey"),
            DetailsProps: [],
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        console.log('ListItems.CloseDrawer', state.Category, state.pk1);

        ReloadData();

        OpenDrawer(false);
    }

    const DetailLink = (item, DetailComponent = "") => {

        console.log('Sync.ListItems.DetailLink', item);

        //let NewDetailComponent = "";
        //if (DetailComponent !== "") {
        //    NewDetailComponent = DetailComponent;
        //} else {
        //    NewDetailComponent = state.DetailComponent;
        //}
       
        // Use Datalink to change the page
        if (props.DetailLink) {
            AddBox(item, DetailComponent);
        } else {
            let Path = `/app/company/${item.CompanyId}?GotoTab=0`;
            navigate(Path, { state: item });
        }
    }



    console.log('Sync.ListItems.state', state);
    console.log('Menu.ListItems.props', props);
    // console.log('ListItems.test.params', params);

    return state.Category && props.id && (
        <Page
            key={props.id}
            title={props.title || state.Category}
        >
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    key="10101010"
                    lg={state.BoxWidth}
                    //md={6}
                    xs={12}
                >
                    <Card
                    // className={classes.root}
                    >
                        <CardHeader
                            title={props.title || state.Category}
                            subheader={state.field2}
                            action={
                                <>
                                    {props.AllowDelete && (
                                        <IconButton aria-label="settings" onClick={() => props.DeleteItem()} size="large">
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                    {props.AllowEdit && (
                                        <IconButton aria-label="settings" onClick={() => props.EditItem()} size="large">
                                            <EditIcon />
                                        </IconButton>
                                    )}

                                    {props.AllowAdd && (

                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => props.AddItem()}
                                        >
                                            Add
                                        </Button>

                                    )}

                                </>
                            }
                        />

                        <CardContent>

                            {state.DataBack
                                && state.DataBack.length > 0 ?
                                (<RenderModule
                                    AddBox={(e) => AddBox(e, "ShowDetailsText")}
                                    SearchWords={props.SearchWords}
                                    CompanyId={state.CompanyId}
                                    CompanyName={state.CompanyName}
                                    title={props.title || state.Category}
                                    disabled={false}
                                    LoadComponenet={state.ListType}
                                    ShowDetailLink={props.ShowDetailLink || true}
                                    DetailLink={(e) => DetailLink(e)}
                                    DataBack={state.DataBack}
                                //  RawData={state.DataBack}
                                />)
                                : (<Skeleton />)}



                        </CardContent>
                    </Card>
                </Grid>

                {state.ShowDetails && (
                    <Grid
                        item
                        key={state.key + "10101010ddss"}
                        lg={6}
                        //md={6}
                        xs={12}
                    >

                        <RenderModule
                            key={state.key}
                            LoadComponenet={state.DetailComponent}
                            {...state.DetailsProps}
                        />

                    </Grid>
                )}
            </Grid>


            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/*    
      <DrawerShow
        LoadComponenet={state.DetailComponent}
        CloseDrawer={(e) => CloseDrawer(e)}
        Category={state.Category}
        CompanyId={state.CompanyId}
        CompanyName={state.CompanyName}
        propss={state.DetailsProps}
        key={state.key}
        DrawerStatus={DrawerStatus}
        OpenDrawer={(e) => OpenDrawer(e)}
        anchor={state.anchor || "left"}
      />
      */}

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                //  CloseDrawer={() => CloseDrawer()}
                DrawerKey={state.DrawerKey}
                DrawerTitle={state.Category}
                key={state.key}
                propss={{
                    //  CloseDrawer: () => CloseDrawer(),
                    Category: props.title || state.Category,
                    key: state.key,
                    //StepPersonal: (e) => AddNextStepOrEnd(e),
                    //StepNumber: props.StepNumber,
                    //StepTeam: state.StepTeam,
                    //ShiftName: props.ShiftName,
                    //ShowEditButton: false,
                    //disabled: false,
                    //EditItem: state.EditItem,
                    CompanyId: state.CompanyId,
                    CompanyName: state.CompanyName,
                    CustomerType: state.CustomerType,
                    //  ImageUpload: true,
                    ...state.DetailsProps
                    //ESCId: props.ESCId
                }}
                DrawerStatus={DrawerStatus}
                CloseDrawer={(e) => CloseDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </Page>
    );
};

export default ListItems;
