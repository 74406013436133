import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { API, graphqlOperation } from "aws-amplify";
import { createSvcbotDb } from '../graphql/mutations';
import { useSelector, useDispatch } from 'react-redux';
//import { SendEmail } from '../utils/SendEmail';
//import { Buffer } from 'buffer';
import {
    TextField,
    //Collapse, 
    Box,
    Button,
    Grid,
    Backdrop,
    CircularProgress,
    Stack,
    Card,
    //Card, FormGroup,
    CardContent,
    CardActions,
    IconButton, Avatar, CardHeader,

    //InputLabel, Select, FormControl, MenuItem, Checkbox,
    Typography,
    //RadioGroup, FormControlLabel, FormLabel, Radio, IconButton, CardHeader,
    //Switch, Tooltip, Divider
} from '@mui/material';
import CheckInStatusSummery from '../List/CheckInStatusSummery';
import {
    removeEmptyStringElements,
    getAutoCompletedata,
    NewPageId,
    UpdateData,
    Pk1SingelItem,
    FindCategoryIcon
} from '../utils/CommonGraphql';

import DrawerShow from '../utils/DrawerModule';
import CheckInInformation from '../List/CheckInInformation';
import EventDataList from '../components/EventDataList2';
import ShowAvatarMessage from '../components/ShowAvatarMessage';
import MaterialGallery from '../Gallery/MaterialGallery';
import ListModule from '../List/ListModule';
import ShowPreSelect from '../components/ShowPreSelect';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';
import Skeleton from '../components/Skeleton';
import DocumentList from '../List/DocumentList';
import { onUpdate, onCreatePk } from '../graphql/subscriptions';
import ClipboardCopy from '../components/ClipboardCopy';
import WOInput from '../components/WOInput';
import ShowProgress from '../components/ShowProgress';

//import GoogleAutoComplete from '../views/modules/GoogleMapPlace';



const UUid = NewPageId("SRDetail");

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.dark,
        //minHeight: '100%',
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

const GetName = (email, rr) => {

    let newname = email.replace("_", " ")
    newname = newname.replace(".", " ")
    newname = newname.replace("-", " ")
    const cleanedInput = newname.split('@')[0]?.replace(/[^\w]/g, ' ').split(' ');
    const capitalizedInput = cleanedInput?.map(word => {
        return word[0]?.toUpperCase() + word.substring(1);
    }).join(' ');

    console.log("GetName", rr, email, capitalizedInput);

    return capitalizedInput
}

const Capatalize = (email) => {

    const cleanedInput = email.split(' ');
    const capitalizedInput = cleanedInput?.map(word => {
        return word[0]?.toUpperCase() + word.substring(1);
    }).join(' ');

    return capitalizedInput
}

export default function SimpleCard(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [DeawerOpen, SetDeawerOpen] = React.useState(false);
    const [Refresh, setRefresh] = React.useState(1);
    const ContractorData = useSelector(state => state.ContractorData); 
    const NowEditing = useSelector(state => state.NowEditing);
    const UserData = useSelector(state => state.UserData);
    const Auth = useSelector(state => state.auth);
    const [loading, SetLoading] = useState(false);
    const initialState = {

        DetailComponent: "AddMessage",
        DrawerTitle: "Add Message",
        NewNumber: UUid,
        AddPrivateMessage: [],
        UpdateNumber: 0,
        UpdateCategory: "Meassage",
        SREvent: {},
        EventData: [],
        ProgressList: [],
        PhotoService: [],
        ShowManagerApprovalButton: false,
        StatusSymbol: "RuleIcon",
        StatusHeading: "Status Bar",
        ShowManagerApproved: "",
        LastStep: [],
        EquipmentList: [],
        LocationList: [],
        DisableAll: !Auth,
       // Refresh: true,
        ProgressWidth: 6,
        DisableAcceptButton: false,
        dir: ""
    }
    const [state, setState] = useState(initialState);

    console.log("CheckinDetails.1.Refresh", Refresh)

    useEffect(() => {
        console.log("SRDetails.props", props)

      //  GetTwilio();

        let DisableAcceptButton = props.DisableAll;

        // use props to populate the page
        if (props.SREvent && props.SREvent.GetData && Refresh === 1) {
            console.log("CheckinDetails.1.GetData")
            if (props.SREvent.ServiceRequest && props.SREvent.ServiceRequest.pk1) {
                //get data from DB
                GetFreshData(props.SREvent.ServiceRequest.pk1, props.SREvent.ServiceRequest.sk1);
                //refresh only one time
                setRefresh(2)
            }
        } else {

            console.log("CheckinDetails.1.populating SRevent")

            PopulteUpdate(props.SREvent);
        }
        
        setState(prevState => ({
            ...prevState,
            DisableAll: props.DisableAll,
            DisableAcceptButton: DisableAcceptButton,
            
        }))

        //let emailname = GetName("jim.dong@slal.com",1)
        //let emailname2 = GetName(emailname,2)
        //let emailname3 = GetName("tony tome",3)

        //let Capatali = Capatalize("tony capatalize",4)

    }, [])
       
    const GetFreshData = async (pk, sk) => {

        let NewData2 = await Pk1SingelItem(pk, sk);
        let NewData = await NewData2;

        if (NewData) {
            let SREvent = JSON.parse(NewData.dataJSON);

            console.log("SRDetailsPkSk.NewData", SREvent)            

            PopulteUpdate(SREvent);
        }
    }

    const PopulteUpdate = (SREvent) => {
        
        let ShowManagerApprovalButton = false;
        let StatusSymbol = "RuleIcon";
        let StatusHeading = "Status Bar";
        let ShowManagerMessage = false;
        let ManagerApprovedBy = [];
        let Read = "";
        // let SREvent = props.SREvent;
        let AllowManagerApproval = false;
        let UpdatePk = "";
        let ShowProgressEdit = true;
        let ShowFeedBack = false;
        let ShowActionBox = false;
        let ShareLink = "";
        let ProgressWidth = 6;

        if (!SREvent.Read) {
            SREvent.Read = [];
        }

        var currentUnixTime = new Date();
        var currentTime = currentUnixTime.toISOString();
        
        //if (SREvent.ManagerApprovalStatus &&
        //    SREvent.ManagerApprovedBy &&
        //    SREvent.ManagerApprovedBy.length > 0) {
        //    ShowManagerMessage = true;
        //}
       

        //if (UserData.CustomerType === "Company" && UserData.Level > 100 && Auth) {
        //    AllowManagerApproval = true;
        //}
        
        if (SREvent.ServiceDetailLink && SREvent.ServiceDetailLink !== "") {
            ShareLink = SREvent.ServiceDetailLink;
        }

        if (SREvent && SREvent.ServiceRequest && SREvent.ServiceRequest.pk1) {
            UpdatePk = SREvent.ServiceRequest.pk1;
        }

        //show action box
        if (UserData.CustomerType === "Company") {
            if (state.ManagerApprovalNeeded
                && state.ManagerApprovalStatus !== "Approved") {
                ShowManagerMessage = true;
            }
        }

        if (SREvent.ManagerApprovalNeeded && UserData.CustomerType === "Company") {
            ShowManagerApprovalButton = true;
        }

        //show action box
        if (UserData.CustomerType === "Contractor") {
          //  if (SREvent.status !== "Accepted" || SREvent.Quote === false) {
                ShowActionBox = true;
          //  }            
        }

        if (SREvent.TriggerType
            && SREvent.TriggerType === "parts"
        ) {
            ShowActionBox = false;
            ShowManagerMessage = false;
        }

        
        let newtitle = ""
        if (SREvent.title && SREvent.title !== "") {
            newtitle = encodeURIComponent(SREvent.title)
        }

        let ShowNotParts = true;

        

        let PostId = SREvent.PostId;
        GetDatafromDB("CheckInId", PostId);
        //console.log("SRDetails.1.2SREvent", SREvent)
        setState(prevState => ({
            ...prevState,
            SREvent: SREvent,
            StatusSymbol: StatusSymbol,
            StatusHeading: StatusHeading,
            ThisPostId: PostId,
            ShowProgressEdit: ShowProgressEdit,
            ShowActionBox: ShowActionBox,
            UpdatePk: UpdatePk,
            ShowManagerApprovalButton: ShowManagerApprovalButton,
            ShowManagerMessage: ShowManagerMessage,
            ManagerApprovedBy: ManagerApprovedBy,
            AllowManagerApproval: AllowManagerApproval,
            ShowFeedBack: ShowFeedBack,
            ShareLink: ShareLink,
            newtitle: newtitle,
            ProgressWidth: ProgressWidth,
            ...SREvent
        }))


    }
    

    const GetDatafromDB = async (Category, cid) => {
        try {
            SetLoading(true);
            console.log("Category, cid", Category, cid)
            const DataString = {
                Category: Category,
                FindArray: [
                    {
                        ArrayName: "DataBack",
                        //DuplicateArray: "RawData",
                        ArraySearchString: ":",
                    }
                ]
            };

            let NewData = await getAutoCompletedata(DataString, cid);

            //group each type of PostId
            console.log("SRDetails.NewData", NewData)

            let Data = NewData.DataBack;

            let PersonList = [];
            let Response = [];
            let AssetsList = [];
            let PolicyList = [];
            let LocationList = [];
            if (Data && Data.length > 0) {
                Data.map(each => {
                    if (each.sk1 && each.sk1.includes("Response")) {
                        Response.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Message")) {
                        PersonList.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Assets")) {
                        AssetsList.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Policy")) {
                        PolicyList.push(each)
                    }
                    if (each.sk1 && each.sk1.includes("Location")) {
                        LocationList.push(each)
                    }
                })
            }

            setState(prevState => ({
                ...prevState,
                PersonList: PersonList,
                AssetsList: AssetsList,
                PolicyList: PolicyList,
                LocationList: LocationList,
                Response: Response
            }))

            SetLoading(false);
        } catch (err) { console.log("SRDetails.GetDatafromDB.err", err) }
    }


    const CloseDrawer = () => {
        console.log("SRDetails.CloseDrawer")
        SetDeawerOpen(false)
    }

    const UpdateAsset = data => {
        console.log("SRDetails.1.UpdateAsset", data)
        if (data) {
            let PostId = props.SREvent.PostId;
            GetDatafromDB("CheckInId", PostId);
        }
    }

    
    const UpdateEvent = data => {
        console.log("SRDetails.1.UpdateEvent", data)
        setState(prevState => ({
            ...prevState,
            SREvent: data,
            ...data
        }))

       // UpdateDatabase(data);
    }

  

    const AddMessage = (number, Category, Message = "") => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AckService",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category
        }))

        SetDeawerOpen(true)
    }

    const AcceptDecline = (number, Category, Message) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "AcceptDecline",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category
        }))

        SetDeawerOpen(true)
    }

    //const ManagerApproval = (number, Category, Message) => {

    //    setState(prevState => ({
    //        ...prevState,
    //        DetailComponent: "NewCheckIn",
    //        dir: "CheckOut",
    //        DrawerTitle: Message,
    //        UpdateNumber: number,
    //        UpdateCategory: Category,
    //    }))

    //    SetDeawerOpen(true)
    //}

    const ManagerApproval = (number, Category, Message) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: "ManagerApproved",
            DrawerTitle: Message,
            UpdateNumber: number,
            UpdateCategory: Category,
        }))

        SetDeawerOpen(true)
    }

    const AdditemDone = () => {
        setState(prevState => ({
            ...prevState,
            TriggerAdd: false,
        }))
    }


    const KeyArray = (data, cat) => {
        console.log("KeyArray", data, cat)
        setState(prevState => ({
            ...prevState,
            [cat]: data
        }));
    }

    const handleChange = (event, name) => {
        //let change = event.target.checked;
        console.log("Switch", event.target.checked, name)
        setState({
            ...state,
            [name]: event.target.checked,
        });
        //props.handleInclude(change, props.step.key);
    };

    
    const ItemHistory = (item) => {

        setState({
            ...state,
            ItemData: item,
            DetailComponent: "ShowDetailsPkSk"
        });

        console.log("SRDetails.ItemHistory", item)

        SetDeawerOpen(true);

    }

    const AddBox = () => {

        setState(prevState => ({
            ...prevState,
            NextLoadModule: "Details",
        }));

        SetDeawerOpen(true);
    }

    const AddAsset = (Category, DetailComponent) => {

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            AddCategory: Category
        }));

        SetDeawerOpen(true);
    }

   


    console.log("SRDetails.state", state)

    //
    return (
        <div className={classes.paper}>
            
            <Grid container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
            >                

                
                <Grid xs={12} md={6} item >
                    <Card>
                        <CardHeader
                            title={state.StatusHeading}
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon(state.StatusSymbol)}
                                </Avatar>
                            }
                        />
                        <CardActions>

                            <CheckInStatusSummery
                                SR={state.SREvent}
                            />
                        </CardActions>
                    </Card>
                </Grid>

                

                {state.ShowManagerApprovalButton ?(
                <Grid
                    xs={12}
                    md={6}
                    item >
                    <Card key={"crd1"} >
                        <CardHeader
                            title="Action"
                            //subheader="Information"

                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("decision")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            <Stack
                                direction={{ xs: 'column' }}
                                spacing={2}
                            >
                                    <Button
                                        onClick={() => AddMessage(state.EventData.length, "Manager", "Manager Action")}
                                        color="primary"
                                        variant="contained"
                                      //  disabled={!state.AllowManagerApproval}
                                    >

                                        Manager Approval
                                    </Button>                

                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                ) : null}

                {state.ShowActionBox ? (
                    <Grid
                        xs={12}
                        md={6}
                        item >
                        <Card key={"crd1"} >
                            <CardHeader
                                title="Action"
                                //subheader="Information"

                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("decision")}
                                    </Avatar>
                                }
                            />

                            <CardContent>
                                <Stack
                                    direction={{ xs: 'column' }}
                                    spacing={2}
                                >
                                    <WOInput
                                        SREvent={state.SREvent}
                                        UpdateEvent={data => UpdateEvent(data)}
                                    />

                                    <ShowProgress
                                        SREvent={state.SREvent}
                                        UpdateEvent={data => UpdateEvent(data)}
                                    />

                                    {state.status !== "Accepted" ? (
                                        <Button
                                            onClick={() => AddMessage(state.EventData.length, "Accepted", "Accept Service Call")}
                                            color="primary"
                                            variant="contained"
                                            disabled={state.status === "Acknolodged"}
                                        >
                                            Acknolodge
                                        </Button>


                                    ) : null}

                                    {state.Quote ? (
                                        <Button
                                            onClick={() => AddMessage(state.EventData.length, "Quote", "Sending Quote")}
                                            color="secondary"
                                            variant="contained"
                                          //  disabled={state.status === "Acknolodged"}
                                        >
                                            Quote / Proposal
                                        </Button>


                                    ) : null}


                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                ) : null}



                {props.SREvent.posterId && (
                    <Grid item xs={12}>
                        <CheckInInformation
                            Data={props.SREvent.posterId}
                        />
                    </Grid>
                )}

                <Grid xs={12} item>
                    <Card key={"crdgff1"}>
                        <CardHeader
                            title="Details"
                            //subheader="Information"
                            action={
                                <IconButton
                                    aria-label="settings"
                                    disabled={state.DisableAll}
                                    onClick={() => AddMessage(state.EventData.length, "Message")}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("AddSMS")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            <EventDataList
                                SREvent={state.SREvent}
                                AddMessage={(number, Category) => AddMessage(number, Category)}
                                UpdateEvent={data => UpdateEvent(data)}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} md={6} item >
                    <Card key={"creedsdfff1"} >
                        <CardHeader
                            title="All Images / Videos"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Document", "AddDocument")}
                                    disabled={state.DisableAll}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Image")}
                                </Avatar>
                            }
                        />
                        <CardContent>

                            <MaterialGallery
                                PhotoService={state.PhotoService}
                            />

                        </CardContent>
                    </Card>
                </Grid>



                <Grid xs={12} md={6} item >
                    <DocumentList
                        DocumentList={state.Documents}
                        AddAsset={() => AddAsset("Document", "AddDocument")}
                        DisableAll={state.DisableAll}
                    />
                </Grid>

                
                <Grid item xs={12} >
                    <Card key={"creedsd1"} >
                        <CardHeader
                            title="Assets"
                                action={NowEditing === "Company" ? (
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Assets", "AddCustomerAsset")}
                                    disabled={state.DisableAll}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            ):null}
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Equipment")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            {state.AssetsList && state.AssetsList.length > 0 ? (
                                <ListModule
                                    DataBack={state.AssetsList}
                                    title="Assets List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={!state.DisableAll}
                                    ShowDetailLink={!state.DisableAll}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={true}
                                    disabled={state.DisableAll}
                                />
                            ) : (<Skeleton />)}

                        </CardContent>
                    </Card>
                </Grid>
                

                {/*
                <Grid item xs={12} md={6} >
                    <Card key={"crkkjhd1"}>
                        <CardHeader
                            title="Service Location"
                            action={
                                <IconButton aria-label="settings"
                                    onClick={() => AddAsset("Location")}
                                    disabled={state.DisableAll}
                                    size="large">
                                    <EditIcon />
                                </IconButton>
                            }
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Location")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            {state.LocationList.length > 0 ? (
                                <ListModule
                                    DataBack={state.LocationList}
                                    title="Location List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={!state.DisableAll}
                                    ShowDetailLink={!state.DisableAll}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={true}
                                    disabled={state.DisableAll}
                                />
                            ) : (<Skeleton />)}

                        </CardContent>
                    </Card>
                </Grid>
                */}

                {state.SpecialInstructions && state.SpecialInstructions !== "" ? (
                    <Grid item xs={12} >
                        <Card key={"crkkjhd1"}>
                            <CardHeader
                                title="Special Instructions"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("AssignmentIcon")}
                                    </Avatar>
                                }
                            />

                            <CardContent>
                                <Typography >
                                    {state.SpecialInstructions}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ) : null}

                {props.SREvent.PreSelect && props.SREvent.PreSelect.length > 0 && (
                    <Grid item xs={12}>
                        <ShowPreSelect
                            disabled={state.DisableAll}
                            PreSelect={props.SREvent.PreSelect}
                        />
                    </Grid>
                )}
       

                {state.ShareLink && state.ShareLink !== "" ? (
                    <Grid item xs={12}>
                        <ClipboardCopy
                            copyText={state.ShareLink}
                            newtitle={state.newtitle}
                        />
                    </Grid>
                ) : null}

                
                

                {state.PersonList && state.PersonList.length > 0 ? (
                <Grid item xs={12} >
                    <Card key={"Personcreedsd1"} >
                        <CardHeader
                            title="People Notified"
                            
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Person")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            
                                <ListModule
                                    DataBack={state.PersonList}
                                    title="People Notified"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={false}
                                    ShowDetailLink={false}
                                   // DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={false}
                                    disabled={true}
                                />
                            

                        </CardContent>
                    </Card>
                </Grid>
                ) : null}

                {state.Response && state.Response.length > 0 ? (
                    <Grid item xs={12} >
                        <Card key={"Personcreedsd1"} >
                            <CardHeader
                                title="Person Responded"
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                        {FindCategoryIcon("Person")}
                                    </Avatar>
                                }
                            />

                            <CardContent>

                                <ListModule
                                    DataBack={state.Response}
                                    title="People Notified"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={false}
                                    ShowDetailLink={false}
                                    // DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={false}
                                    disabled={true}
                                />


                            </CardContent>
                        </Card>
                    </Grid>
                ) : null}

                {state.PolicyList && state.PolicyList.length > 0 ? (
                <Grid item xs={12} >
                    <Card key={"Policycreedsd1"} >
                        <CardHeader
                            title="Shift Notified"
                            
                            avatar={
                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                    {FindCategoryIcon("Policy")}
                                </Avatar>
                            }
                        />

                        <CardContent>
                            
                                <ListModule
                                    DataBack={state.PolicyList}
                                    title="Policy List"
                                    AddBox={e => AddBox(e)}
                                    AllowDelete={false}
                                    ShowDetailLink={false}
                                    DetailLink={(e) => ItemHistory(e)}
                                    AllowEdit={false}
                                    disabled={true}
                                />
                            

                        </CardContent>
                    </Card>
                </Grid>
                ) : null}

            </Grid>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerTitle={props.DrawerTitle}
                //LoadComponenet="ItemAddPage"
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    SREvent: state.SREvent,
                    UpdateEvent: data => UpdateEvent(data),
                    UpdateAsset: data => UpdateAsset(data),
                    CurrentProgressStep: state.CurrentProgressStep,
                    UpdateNumber: state.UpdateNumber,
                    UpdateCategory: state.UpdateCategory,
                    Category: state.AddCategory,
                    LastStep: state.LastStep,
                    DrawerTitle: state.DrawerTitle,
                    dir: state.dir,
                    ItemData: state.ItemData,
                    key: NewPageId("srdet"),
                    id: NewPageId("srdetil"),
                }}
                DrawerStatus={DeawerOpen}
                // CloseDrawer={(e) => SetDeawerOpen(false)}
                OpenDrawer={(e) => SetDeawerOpen(false)}
                anchor="left"
            />
        </div>
    );
}
