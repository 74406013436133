import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useParams, useLocation, Link } from 'react-router-dom';
//import { useLocation } from "react-router-dom"
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Container,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
} from '@mui/material';
//import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
//import { Search as SearchIcon } from 'react-feather';
//import AutoComplateBox from '../components/AutoComplete';
import { NewPageId } from '../utils/CommonGraphql';
//import Tabs from '../components/Tabs';
import { useDispatch } from 'react-redux';
//import { ContractorTabs, CompanyTabs, HQTabs, PersonTabs, AssetTabs } from '../Company/TabsList';
import Page from '../components/Page';
import RenderModule from '../utils/RenderModule';
import ToolbarHeading from '../components/ToolbarHeading';


//const useStyles = makeStyles((theme) => ({
//  root: {
//    margin: theme.spacing(1)
//  },
//  importButton: {
//    marginRight: theme.spacing(1)
//  },
//  exportButton: {
//    marginRight: theme.spacing(1)
//  }
//}));

//let TempLoadComponent = [
//    {
//        LoadComponenet: "ListItems",
//        title: "title 1",
//        size: 6,
//    },
//    {
//        LoadComponenet: "ListItems",
//        title: "title 2",
//        size: 6,
//    },
    
//    {
//        LoadComponenet: "ListItems",
//        title: "title 2",
//        size: 6,
//    },
//]



const Toolbar = (props) => {
    // const classes = useStyles();
    const dispatch = useDispatch();
    const params = useParams();
    const [SelectCompany, SetSelectCompany] = useState(false);
    const ContractorData = useSelector(state => state.ContractorData);
    const UserData = useSelector(state => state.UserData);
    const cid = useSelector(state => state.cid);
    const items = useLocation();

    // const match = useRouteMatch();

    const initialState = {
        TapsProps: {},
        title: "",
        SearchWords: "",
        ShiftShow: [],
        PopulatePage: [{
            LoadComponenet: "ListItems",
            title: "List",
            size: 6,
        }]
    };

    const [state, setState] = useState(initialState);

    useEffect(() => {
        NewValues()
    }, [])

    useEffect(() => {
        NewValues()
    }, [items, params])

    const NewValues = () => {

        let Newpageiddd = NewPageId("page");

        dispatch({ type: 'NOW_EDITING', payload: UserData.CustomerType });

        let propss = {
            CompanyId: props.CompanyId,
            LoadComponenet: "ListItems",
            UserEditor: props.UserEditor,
            NowEditing: props.NowEditing,
            CustomerType: props.CustomerType,
            //ActiveCategory: props.ActiveCategory,
            //Activefield1: props.Activefield1,
            //ActiveKey: props.ActiveKey,
            CompanyName: props.CompanyName,
            Category: params.ShowMenuComponent,
            PopulatePage: []
        };

        //seperrate properties
        //let LoadComponenet = propss.LoadComponenet;
        //delete propss.LoadComponenet;

        //let PopulatePage = props.PopulatePage;
        //delete propss.PopulatePage;

        

        if (items && items.state) {
            if (items.state.item) {
                propss = {
                    ...items.state.item,
                    ActiveCategory: items.state.item.Category,
                    Activefield1: items.state.item.field1,
                    ActiveKey: items.state.item.key
                }
            }
        }

        console.log('ShowMenuComponent.propss2', propss);

        setState(prevState => ({
            ...prevState,
            ...propss,
            Newpageiddd: Newpageiddd
        }));

    }

    const CommonState = (item) => {

        setState(prevState => ({
            ...prevState,
            [item.itemname]: item.itemvalue
        }));

    }

    console.log('Menu.ShowMenuComponent.state', state);
    console.log('Menu.ShowMenuComponent.props', props);
    console.log('Menu.ShowMenuComponent.items.state.item', items.state.item);

    return (

        <Page
            title={state.title}
            key={state.Newpageiddd + "page"}
        >
            <Grid container spacing={1} >

                {/* 
                <Grid item xs={12}>
                    <Typography variant="h2">
                        {UserData.CompanyName}
                    </Typography>
                </Grid>
                */}

                {/*
                <Grid item xs={12}>
                    <Typography>
                        {"HQ > Branch > Company > Location > Equipment"}
                    </Typography>
                </Grid>
               <Grid item xs={12}>
                    <ToolbarHeading
                        Headings={state.Category}
                        Category={state.Category}
                        SearchWords={state.SearchWords}
                        PutinCommonState={(e) => CommonState(e)}
                        disabled={props.disabled}
                    />
                </Grid>
*/}
                {state.PopulatePage
                    && state.PopulatePage.length > 0
                    && state.PopulatePage.map((each, indd) => (
                    <Grid item xs={12} lg={each.size} key={state.Newpageiddd + "grd" + indd} >
                        {each.Category && (<RenderModule
                            key={state.Newpageiddd + "page" + indd}
                            id={state.Newpageiddd + "idd" + indd}
                            SearchWords={state.SearchWords}
                            ShiftShow={state.ShiftShow}
                            PutinCommonState={(e) => CommonState(e)}
                            Category={each.Category}
                            {...each}
                        />)}
                    </Grid>
                ))}
            </Grid>
        </Page>

    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    ProcessSearch: PropTypes.func
};

export default Toolbar;
