import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputAdornment from '@mui/material/InputAdornment';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, Button, TextField } from '@mui/material';
import {
    removeEmptyStringElements,
    UpdateData,
} from '../utils/CommonGraphql';

export default function CustomizedInputBase(props) {

    const [WorkOrder, SetWorkOrder] = useState("");
    const [message, SetMessage] = useState("");
    const [Dissable, SetDisable] = useState(false);
    const [ShowButton, SetShowButton] = useState(false);

    useEffect(() => {
        console.log("SRDetails.props", props)

        if (props.SREvent && props.SREvent.WOID && props.SREvent.WOID !== "") {
            SetWorkOrder(props.SREvent.WOID);
          //  SetDisable(true);
           // SetShowButton(true);
        }

    }, [])

    useEffect(() => {
        if (WorkOrder !== "" && props.SREvent.WOID !== WorkOrder) {
            SetShowButton(true);
        }
    }, [WorkOrder])

    const UpdateSREvent = () => {

        console.log("WorkOrder.1", Dissable, WorkOrder)

        if (Dissable) {
            SetDisable(false)
        } else {

            if (WorkOrder !== "") {

                SetMessage("");

                let SREvent = {
                    ...props.SREvent,
                    WOID: WorkOrder,
                    SearchWords: `${props.SREvent.SearchWords} Work Order: ${WorkOrder}`
                }

                console.log("WorkOrder.2", SREvent)

                var ValueToUpdate = {
                    pk1: SREvent.ServiceRequest.pk1,
                    sk1: SREvent.ServiceRequest.sk1,
                    gsi1Pk2: SREvent.gsi1Pk2,
                    gsi2Pk2: SREvent.gsi2Pk2,
                    dataJSON: JSON.stringify(removeEmptyStringElements(SREvent)),
                };

                //console.log("SRDetails.1.pksk", ValueToUpdate)            

                UpdateData(ValueToUpdate, "AddDocument")
                    .then((up) => {
                        console.log("WorkOrder.3", up)

                        if (props.UpdateEvent) {
                            props.UpdateEvent(SREvent);
                        }

                        SetDisable(true)

                        // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                    })
                    .catch((err) => console.log("SRDetails.failed", err));

            } else {
                SetMessage("Please enter Work Order # and press plus sign")
            }
        }

    }

    return (
        <>
       
            {/* 
            <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Work Order #</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-password"
                    type='text'
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                               // disabled={Dissable}
                                onClick={() => UpdateSREvent()}
                                edge="end"
                            >
                                {Dissable ? <EditIcon /> : <AddBoxIcon />}
                            </IconButton>
                        </InputAdornment>
                    }
                    label="Work Order #"
                    disabled={Dissable}
                    onChange={event => SetWorkOrder(event.target.value)}
                    value={WorkOrder}
                />
            </FormControl>
            */}

            <Stack direction="row" spacing={1}>

                <TextField
                    id="outlined-basic"
                    label="Work Order #"
                 //   disabled={Dissable}
                    fullWidth
                    variant="outlined"
                    onChange={event => SetWorkOrder(event.target.value)}
                    value={WorkOrder}
                />

                {/*
                    Dissable ? (<EditIcon onClick={() => UpdateSREvent()} />) : (
                <Button
                    variant="text"
                    onClick={() => UpdateSREvent()}
                    color="primary"
                >
                    Update
                </Button>
                    )
                */}

                {ShowButton ? (
                    <Button
                        variant="text"
                        onClick={() => UpdateSREvent()}
                        color="error"
                    >
                        Save
                    </Button>
                ): null}

            </Stack>

            
            <Typography variant="body1" color="error" gutter>
                {message}
            </Typography>

        </>
    );
}

