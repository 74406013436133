export const StepValueArrayBackup = [
    {
        "UseCustomSMS": true,
        "SMSQuestions": [
            {
                "VariableInput": true,
                "include": false,
                "field1": "Please provide location, room, bldg, etc. where service is needed? ",
                "Category": "Details",
                "keywords": {
                    "ESC": [],
                    "words": ","
                },
                "defaultValue": true,
                "Question": "Please provide location, room, bldg, etc. where service is needed? ",
                "label": "Please provide location, room, bldg, etc. where service is needed? ",
                "PreSelect": false,
                "key": "Details_PVGyZDKf"
            },
            {
                "VariableInput": true,
                "include": false,
                "field1": "Please provide the name/tag of the equipment or system that need service. You can include picture of the equipment nameplate",
                "Category": "Equipment",
                "keywords": {
                    "ESC": []
                },
                "defaultValue": true,
                "Question": "Equipment Info",
                "label": "Please provide the name/tag of the equipment or system that need service. You can include picture of the equipment nameplate",
                "PreSelect": false,
                "key": "Equipment"
            },
            {
                "VariableInput": true,
                "include": false,
                "field1": "Would like quote/proposal for the work? (yes/no)",
                "Category": "Quote",
                "keywords": {
                    "ESC": []
                },
                "defaultValue": true,
                "Question": "Would like quote/proposal for the work? (yes/no)",
                "label": "Would like quote/proposal for the work? (yes/no)",
                "PreSelect": false,
                "key": "Quote"
            },
            {
                "VariableInput": true,
                "include": false,
                "field1": "Optional - include pictures/short video or say no images",
                "Category": "Images",
                "keywords": {
                    "ESC": []
                },
                "defaultValue": true,
                "Question": "Optional - include pictures/short video or say no images",
                "label": "Optional - include pictures/short video or say no images",
                "PreSelect": false,
                "key": "Images"
            },
            {
                "VariableInput": false,
                "include": false,
                "field1": "Click here to mark GPS location.",
                "Category": "MarkLocation",
                "keywords": {
                    "ESC": []
                },
                "Question": "Click here to mark GPS location.",
                "label": "Click here to mark GPS location.",
                "PreSelect": false,
                "key": "MarkLocation"
            }
        ],
        "DetailsProps": {
            "VariableInput": true,
            "include": false,
            "field1": "Please provide location, room, bldg, etc. where service is needed? ",
            "Category": "Details",
            "keywords": {
                "ESC": [],
                "words": ","
            },
            "defaultValue": true,
            "Question": "Please provide location, room, bldg, etc. where service is needed? ",
            "label": "Please provide location, room, bldg, etc. where service is needed? ",
            "PreSelect": false,
            "key": "Details_PVGyZDKf"
        },
        "KeyId": "SMSQuestions",
        "id": 1
    },
    {
        "CompanyESC": [
            {
                "pk1": "Data::Owner::Info::sninc.us::90803",
                "sk1": "Data::Online::Lambda::ESC::Time#ESC_A1SuG6aF"
            }
        ],
        "CompanyESCDefault": [
            "Data::Online::Lambda::ESC::Time#ESC_A1SuG6aF"
        ],
        "id": 2,
        "KeyId": "ESCSelectContractor",
        "UseCustomESC": false
    },
    {
        "UseCustomESC": false,
        "CompanyESC": [],
        "CompanyESCDefault": [],
        "id": 3,
        "KeyId": "ESCQuote"
    },
    {
        "ReplyEmail": "no-reply@sninc.us",
        "phone": "+12829292922",
        "EndOfQuestions": "Notification has been sent to On-Call Personal. Use #new to start new service request. Use #cancel to cancel this service.",
        "AllowServiceMessage": "Service call received, the system is processing the service request",
        "DenyServiceMessage": "You are not allowed to Request Service with SMS. Please call main office undefined",
        "NotinSystemMessage": "Sorry, we were not able to find you in our system. Please contact the main number undefined to request service",
        "NotificationSent": "Notification has been sent to On-Call team.",
        "SMSFeedbackMessages": true,
        "RepeatCustomerMessage": "This number is for new projects or service work. If you are texting regarding an ongoing project or issue, please contact us at 555-555-5555. If this is new work, please answer following question",
        "CustomerType": false,
        "ShowRepeatCustomer": true,
        "FirstTime": "This is New Service service number. The system will ask you to provide some information and possibly upload pictures. This helps us offer you faster and more accurate service. Since, this is your first time, please provide following info:",
        "MessageList": [
            {
                "name": "EndOfQuestions",
                "label": "End Of Questions",
                "value": "Notification has been sent to On-Call Personal. Use #new to start new service request. Use #cancel to cancel this service.",
                "key": "EndOfQuestions"
            }
        ],
        "KeyId": "SMSMessagesBack",
        "id": 5,
        "ShowCustomerType": true
    },
    {
        "UseCustomESC": false,
        "CompanyESC": [],
        "CompanyESCDefault": [],
        "id": 4,
        "KeyId": "Emergency"
    },
    {
        "Email": true,
        "Name": false,
        "Address": false,
        "CustomerType": "Individual",
        "AddQuestions": [
            {
                "key": "email",
                "Category": "email",
                "label": "Please provide your email address",
                "field1": "Please provide your email address",
                "Question": "Please provide your email address",
                "include": false,
                "PreSelect": false,
                "VariableInput": true,
                "keywords": {
                    "ESC": []
                },
                "defaultValue": true
            },
            {
                "key": "Name",
                "Category": "Name",
                "label": "Please provide your Name",
                "field1": "Please provide your Name",
                "Question": "Please provide your Name",
                "include": false,
                "PreSelect": false,
                "VariableInput": false,
                "keywords": {
                    "ESC": []
                },
                "defaultValue": false
            },
            {
                "key": "Address",
                "Category": "Address",
                "label": "Please provide your Address",
                "field1": "Please provide your Address",
                "Question": "Please provide your Address",
                "include": false,
                "PreSelect": false,
                "VariableInput": false,
                "keywords": {
                    "ESC": []
                },
                "defaultValue": false
            }
        ],
        "id": 0,
        "KeyId": "SelectCustomerType"
    }
];