import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Tooltip, Chip, Box, Grid } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useSelector } from 'react-redux';

let ShowChip = true;
let actioncolor = "";

export default function RecipeReviewCard(props) {
    const UserData = useSelector(state => state.UserData);
    const WebsiteType = useSelector(state => state.WebsiteType);
    console.log("SRSummery.props", props)

    return props.SR && (

        <Grid
            container
            direction="row"
        >

            {WebsiteType === "ContractorCheckIn" ?
                (props.SR.status === "CheckOut" ? (
                <Grid>
                    <Tooltip title="Status">
                        <Chip
                            label={`Status: ${props.SR.Direction}`}
                            color="primary"
                            style={{ background: "#007f00" }}
                        />
                    </Tooltip>
                </Grid>
            ) : (
                <Grid>
                        <Tooltip title="Status">
                        <Chip
                            label={`Status: ${props.SR.Direction}`}
                            color="primary"
                                style={{ background: "#0000ff" }}
                        />
                    </Tooltip>
                </Grid>
            )):null}

            {props.SR.Documents && props.SR.Documents.length > 0 ? (
                <Grid>
                    <Tooltip title="Documents">
                        <Chip
                            label={`Documents`}
                            icon={<DoneIcon />}
                            color="primary"
                            style={{ background: "#7ed321" }}
                        />
                    </Tooltip>
                </Grid>
            ) : null}

            {props.SR.PhotoService && props.SR.PhotoService.length > 0 ? (
                <Grid>
                    <Tooltip title="Pictures">
                        <Chip
                            label={`Pictures`}
                            icon={<DoneIcon />}
                            color="primary"
                            style={{ background: "#f5a623" }}
                        />
                    </Tooltip>
                </Grid>
            ) : null}

        

            {props.SR.Quote === true ? (
                <Grid>
                    <Tooltip title="Quote Needed">
                        <Chip
                            label="Quote"
                            //  onClick={() => SRDetails()}
                            color="primary"
                            //   size="small"
                            style={{
                                background: "#009933"
                            }}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
            ) : null}

            {props.SR.Emergency === true ? (
                <Grid>
                    <Tooltip title="Emergency">
                        <Chip
                            label="Emergency"
                            color="primary"
                            style={{
                                background: "#f51307"
                            }}
                        />
                    </Tooltip>
                </Grid>
            ) : null}

            {props.SR.ManagerApprovalNeeded && props.SR.ManagerApprovalStatus !== "Approved" ? (
                <Grid>
                    <Tooltip title="Manager Approval">
                        <Chip
                            label={`Manager Approval: Pending`}
                            //  onClick={() => SRDetails()}
                            color="primary"
                            //   size="small"
                            style={{ background: "#4287f5" }}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
            ): null}

            {props.SR.ManagerApprovedBy && props.SR.ManagerApprovedBy.length > 0 && (
                props.SR.ManagerApprovedBy.map((eachProgress, indd) => {
                    ShowChip = true;
                    switch (eachProgress.Action) {
                        case "Approved":
                            actioncolor = "#009933";
                            break;
                        case "Canceled":
                            actioncolor = "#ff0000";
                            break;
                        case "Rejected":
                            actioncolor = "#ff0000";
                            break;
                        case "Accepted":
                            actioncolor = "#009933";
                            break;
                        default:
                            actioncolor = "#000000";
                    }

                    if (eachProgress.Action && eachProgress.Action.includes("Response:")) {
                        if (UserData.CustomerType !== "Contractor") {
                            ShowChip = false;
                        }
                    }

                    return ShowChip && (
                        eachProgress.avatar && eachProgress.avatar !== "" ? (
                            <Grid>
                                <Chip
                                    avatar={<Avatar alt={eachProgress.label} src={eachProgress.avatar} />}
                                    label={eachProgress.Action || eachProgress.Body}
                                    variant="outlined"
                                    style={{ background: actioncolor, color: "#ffffff" }}
                                    key={"Chipavtr3" + indd}
                                />
                            </Grid>
                        ) : (
                            <Grid>
                                <Chip
                                        label={eachProgress.Action && `${eachProgress.SenderName}: ${eachProgress.Action}`}
                                    style={{ background: actioncolor, color: "#ffffff" }}
                                    variant="outlined"
                                    key={"Chipavtr4" + indd}
                                />
                            </Grid>
                        )

                    )

                })
            )}

            {props.SR.CompanyStatus === "Accepted" ? (
                <Grid>
                    <Tooltip title="Final: Accepted">
                        <Chip
                            label="Final: Accepted"
                            color="primary"
                            style={{
                                background: "#009933"
                            }}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
                ) : null
            }         

            {props.SR.Progress && props.SR.Progress !== 0 ? (
                <Grid>
                    <Tooltip title="Progress">
                        <Chip
                            label={`Progress: ${props.SR.Progress}`}
                            variant="outlined"
                          //  color="primary"
                            style={{ background: "#FFFFC5"}}
                        // icon={<DoneIcon />}
                        />
                    </Tooltip>
                </Grid>
            ) : null
            }    

        </Grid>
    );
}
