import React, { useState } from "react";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Button,
    Typography,
    Grid,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
//import { useSelector } from 'react-redux';
//import { Search as SearchIcon } from 'react-feather';
//import AutoComplateBox from '../components/AutoComplete';
import { NewPageId } from '../utils/CommonGraphql';
import DrawerShow from '../utils/DrawerModule';


const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

const Newpageiddd = NewPageId("toolbar")

const Toolbar = ({ className, ...rest }) => {
    const classes = useStyles();
    const [SelectCompany, SetSelectCompany] = useState(false);
    //const ContractorData = useSelector(state => state.ContractorData);
    //const UserData = useSelector(state => state.UserData);
    //const cid = useSelector(state => state.cid);
    const [DrawerStatus, OpenDrawer] = useState(false);

    const initialState = {
        SearchWords: ""
    }
    const [state, setState] = useState(initialState);

    //const [UserCid, SetUserCid] = useState(UserData);

    //const initialState = {
    //  CurrentCompany: UserCid.CompanyName,
    //};

    // const [state, setState] = useState(initialState);

    //const GetCompanyName = (data) => {
    //  //console.log("data", data);
    //  let CurrentCompany = data.field1;

    //  //if (rest.AddCompany) {
    //  //  CurrentCompany = `${state.CurrentCompany} + ${data.field1}`;
    //  //}

    //  setState(prevState => ({
    //    ...prevState,
    //    CurrentCompany: CurrentCompany,
    //  }));

    //  SetSelectCompany(false);
    //  rest.CompanyData(data);

    //}

    const CommonState = (itemvalue, itemname) => {

        setState(prevState => ({
            ...prevState,
            [itemname]: itemvalue
        }));

        if (rest.PutinCommonState) {
            rest.PutinCommonState({ itemvalue: itemvalue, itemname: itemname })
        }
    }

    const AddItem = (data) => {
        console.log("ListItems.AddItem", data);
        let DetailComponent = "ItemAddPage";

        if (data === "ESC") {
            DetailComponent = "ESCSetupPage"
        }

        if (data === "Sub-Contractor") {
            DetailComponent = "AddCustomer"
        }

        if (data === "Customers") {
            DetailComponent = "AddCustomer"
        }

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            DrawerKey: NewPageId("DrawerKey"),
            DetailsProps: [],
        }))

        OpenDrawer(true);
    }

    const AddBulk = (data) => {
        console.log("ListItems.AddItem", data);
        let DetailComponent = "UploadExcel";

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            DrawerKey: NewPageId("DrawerKey"),
            DetailsProps: [],
        }))

        OpenDrawer(true);
    }

    const InvitePerson = (data) => {
        console.log("InvitePerson", data);
        let DetailComponent = "Invitebox";

        setState(prevState => ({
            ...prevState,
            DetailComponent: DetailComponent,
            DrawerKey: NewPageId("DrawerKey"),
            DetailsProps: {
                ActiveKey: rest.CompanyId,
                Activefield1: rest.CompanyName || "-"
            },
        }))

        OpenDrawer(true);
    }

    const CloseDrawer = (c) => {
        // GetDatafromDB(state.Category, state.pk1);
        OpenDrawer(false);
    }

    console.log('ToolbarHeading.state', state);
    console.log('ToolbarHeading.props', rest);

    return (
        <div
            className={clsx(classes.root, className)}
            key={"key" + Newpageiddd}
            id={"id" + Newpageiddd}
        >
            <Box mt={2}>
                <Grid container spacing={1} >
                    {!rest.HideHeading && (
                        <>
                            <Grid item xs={12} sm={6} >
                                <Typography variant="h4" component="span">
                                    {rest.Headings}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                >

                                    {rest.Category === "Assets" ? (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => AddBulk(rest.Category)}
                                        disabled={rest.disabled}
                                        sx={{mr: 1}}
                                    >
                                        Import Excel
                                    </Button>
                                    ) : null}

                                    {rest.Category === "Person" ? (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => InvitePerson(rest.Category)}
                                            disabled={rest.disabled}
                                            sx={{ mr: 1 }}
                                        >
                                            Invite Person
                                        </Button>
                                    ) : null}

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => AddItem(rest.Category)}
                                        disabled={rest.disabled}
                                    >
                                        {`Add ${rest.Category}`}
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} >

                        <Card>
                            <CardContent>
                                <Box maxWidth={500}>
                                    <TextField
                                        fullWidth
                                        disabled={rest.disabled}
                                        onChange={(e) => CommonState(e.target.value, "SearchWords")}
                                        placeholder={`Search ${rest.Category}`}
                                        variant="outlined"
                                    />
                                </Box>
                            </CardContent>
                        </Card>

                    </Grid>
                </Grid>
            </Box>

            <DrawerShow
                LoadComponenet={state.DetailComponent}
                CloseDrawer={() => CloseDrawer()}
                DrawerKey={state.DrawerKey}
                DrawerTitle={state.Category}
                propss={{
                    CloseDrawer: () => CloseDrawer(),
                    Category: rest.Category,
                    key: state.DrawerKey,
                    id: state.DrawerKey,
                    CompanyId: rest.CompanyId,
                    TriggerUpdate: true,
                    CompanyName: rest.CompanyName,
                    CustomerType: rest.CustomerType,
                    ImageUpload: true,
                    ...state.DetailsProps
                }}
                DrawerStatus={DrawerStatus}
                OpenDrawer={(e) => OpenDrawer(e)}
                anchor={state.anchor || "left"}
            />

        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string,
    ProcessSearch: PropTypes.func
};

export default Toolbar;
