import React, { useState, useEffect } from 'react';
import {
    Card, CardContent,
    Button, Stepper, Step, StepContent, StepLabel,
    Backdrop, CircularProgress,
    Typography,
    TextField, Grid, CardHeader,
    Avatar,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import ShareIcon from '@mui/icons-material/Share';
import { NewPageId } from '../utils/CommonGraphql';
import PaymentSystem from '../Payment/PaypalModule4';
import SubscriptionPage from '../Payment/SubscriptionPage';
import { SendEmail } from '../utils/SendEmail';
import AddTwilioNumber from '../MainWebsite/AddTwilioNumber';
import { EnterSMS } from '../MainWebsite/CreateDBEntries';
import { useSelector } from 'react-redux';
import { API } from "aws-amplify";
import { Buffer } from 'buffer';
import InviteSMSList from './InviteSMSList';
import InviteCompanyPersonnel from '../components/InviteCompanyPersonnel';
import { red } from '@mui/material/colors';




//var currentUnixTime = new Date();
//var currentTime = currentUnixTime.toISOString();
//var TimeStamp = currentUnixTime.getTime();
//let ShowDate = currentUnixTime.toLocaleString();
//const UUid = NewPageId("x");
export default function SimpleCard(props) {
    const [loading, SetLoading] = useState(false);
    const NowEditing = useSelector(state => state.NowEditing);
    const UserData = useSelector(state => state.UserData);
    const ContractorData = useSelector(state => state.ContractorData)
    const [activeStep, setActiveStep] = useState(0);
    const initialState = {
        AlarmMessage: "",
        OpenAlert: false,
        PaymentMade: false,
        SelectedNumber: "",
        DoneDone: false,
        Amount: 29,
        Title: "",
        SMSList: []
    }
    const [state, setState] = useState(initialState);

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)
                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);

    useEffect(() => {

        let PaymentMessag = "Please select the numbers that will be included in the email for this customer";
        let StepOne = false;

        if (UserData.CustomerType === "Contractor" && NowEditing === "Company") {
            StepOne = true;
        }

        setState(prevState => ({
            ...prevState,
            PaymentMessag: PaymentMessag,
            StepOne: StepOne
        }));

        

    }, []);



    const handleClose = (event, reason) => {
        // handle close of Alert

        setState(prevState => ({
            ...prevState,
            AlarmMessage: "",
            OpenAlert: false
        }));

    };

    const handleNext = (verr) => {

        console.log("AddSMSNumber.verr", verr)

        let AlarmMessage = "";
        let OpenAlert = false;  //

        //if (verr === "PaymentMade" && !state.PaymentMade) {
        //    OpenAlert = true;
        //    AlarmMessage += ` * Please Select Payment Method`;
        //}

        if (verr === "SMS"
            && state.SMSList.length === 0
            && NowEditing === "Company"
        ) {
            OpenAlert = true;
            AlarmMessage = ` * Please Select Number`;
        } else {
            AlarmMessage = "";
        }



        if (OpenAlert) {

            setState(prevState => ({
                ...prevState,
                AlarmMessage: AlarmMessage
            }));

        } else {

            setActiveStep((prevActiveStep) => prevActiveStep + 1);

        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };




    const SetValues = (e, name) => {

        setState(prevState => ({
            ...prevState,
            [name]: e,
        }));



    }


    console.log("AddSMSNumber.state", state);
    console.log("AddSMSNumber.props", props)

    return (
        <>
            <div>
                <Card>
                    <CardHeader
                        title={`Invite ${props.Activefield1} Personnel`}
                        subheader={`This module creates a link or email to signup ${props.Activefield1} personnel. The step two will show a link. Whoever uses this link to sign up will be added to ${props.Activefield1} company account`}
                        avatar={
                            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" >
                                <ShareIcon />
                            </Avatar>
                        }
                    />
                    
                    <CardContent>

                        <Stepper activeStep={activeStep} orientation="vertical">

                            {state.StepOne ? (
                            <Step key={0}>
                                <StepLabel>Select Numbers</StepLabel>
                                <StepContent>
                                    <Grid container spacing={1}>

                                        <Grid xs={12} item>
                                            <Typography>
                                                {state.PaymentMessag}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} item>
                                            <InviteSMSList
                                                SMSList={(e) => SetValues(e, "SMSList")}
                                            />
                                        </Grid>
                                        <Grid xs={12} item>
                                            <Typography color="error">
                                                {state.AlarmMessage}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} item>

                                            <div >
                                                <div>

                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => handleNext("SMS")}
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                            ) : null}


                            <Step key={1}>
                                <StepLabel>Create Invitation Link or Email</StepLabel>
                                <StepContent>
                                    <Grid container spacing={1}>

                                        <Grid xs={12} item>
                                            <InviteCompanyPersonnel
                                                StepOne={state.StepOne}
                                                NowEditing={NowEditing}
                                                ContractorIdd={ContractorData.CompanyId}
                                                ContractorName={ContractorData.field1}
                                                SMSList={state.SMSList}
                                                {...props}
                                            />
                                        </Grid>

                                    </Grid>

                                </StepContent>
                            </Step>



                        </Stepper>
                    </CardContent>
                </Card>
            </div>


            <Backdrop
                open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={state.OpenAlert}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={state.AlarmMessage}
            />

        </>
    );
}
