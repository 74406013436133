import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import InputAdornment from '@mui/material/InputAdornment';
import AddBoxIcon from '@mui/icons-material/AddBox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, Button, Select, MenuItem } from '@mui/material';
import {
    removeEmptyStringElements,
    UpdateData,
} from '../utils/CommonGraphql';

let Progress = ["0%", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "Completed"]

export default function CustomizedInputBase(props) {

    const [WorkOrder, SetWorkOrder] = useState("");
    const [message, SetMessage] = useState("");
    const [Dissable, SetDisable] = useState(false);
    const [ShowButton, SetShowButton] = useState(false);

    useEffect(() => {
        console.log("SRDetails.props", props)

        if (props.SREvent && props.SREvent.Progress && props.SREvent.Progress !== "") {
            SetWorkOrder(props.SREvent.Progress);
          //  SetDisable(true)
        }

    }, [])

    useEffect(() => {
        if (WorkOrder !== "" && props.SREvent.Progress !== WorkOrder) {
            SetShowButton(true);
        }
    }, [WorkOrder])

    const UpdateSREvent = () => {

        console.log("WorkOrder.1", Dissable, WorkOrder)

       
            if (WorkOrder !== "") {

                SetMessage("");

                let SREvent = {
                    ...props.SREvent,
                    Progress: WorkOrder
                }

                console.log("WorkOrder.2", SREvent)

                var ValueToUpdate = {
                    pk1: SREvent.ServiceRequest.pk1,
                    sk1: SREvent.ServiceRequest.sk1,
                    gsi1Pk2: SREvent.gsi1Pk2,
                    gsi2Pk2: SREvent.gsi2Pk2,
                    dataJSON: JSON.stringify(removeEmptyStringElements(SREvent)),
                };

                //console.log("SRDetails.1.pksk", ValueToUpdate)            

                UpdateData(ValueToUpdate, "AddDocument")
                    .then((up) => {
                        console.log("WorkOrder.3", up)

                        if (props.UpdateEvent) {
                            props.UpdateEvent(SREvent);
                        }

                        SetDisable(true)

                        // dispatch({ type: 'TRIGGER_UPDATE', payload: true });
                    })
                    .catch((err) => console.log("SRDetails.failed", err));

            } else {
                SetMessage("Please enter Work Order # and press plus sign")
            }
        

    }

    return (
        <>

            <Stack direction="row" spacing={1}>
                
                    <FormControl variant="outlined" style={{ width: '100%' }} >
                        <InputLabel id="demo-simple-select-outlined-label">Work Progress</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            autoWidth={true}
                            value={WorkOrder}
                            onChange={(e) => SetWorkOrder(e.target.value)}
                            label="Work Progress"
                        >
                            {Progress.map((each, indd) => (
                                <MenuItem key={indd} value={each}>
                                    {each}
                                </MenuItem>
                            ))}
                        </Select>
                </FormControl >

                {/* 
                <Button
                    variant="text"
                    onClick={() => UpdateSREvent()}
                    color="primary"
                >
                    Update
                </Button>     
                */}

                {ShowButton ? (
                    <Button
                        variant="text"
                        onClick={() => UpdateSREvent()}
                        color="error"
                    >
                        Save
                    </Button>
                ) : null}

            </Stack>

            
            
        </>
    );
}

