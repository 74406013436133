import React, { useEffect, useState } from 'react';
import {
    TextField,
    //Collapse,
    Typography,
    //Paper,
    Grid,
    CardHeader, Card, Button, CardContent, InputLabel,
    Backdrop,
    CircularProgress,
    //Card,
    //FormGroup,
    //CardContent,
    //CardActions,
    Snackbar,
    //CardHeader,
    FormGroup, FormControlLabel, Checkbox,
    //Switch,
    //Tooltip, Divider
} from '@mui/material';
import { NewPageId, removeEmptyStringElements } from '../utils/CommonGraphql';
import { API } from "aws-amplify";
import ListModule from '../List/ListModule';
import DrawerShow from '../utils/DrawerModule';
import { SendEmail } from '../utils/SendEmail';
import PhoneInput from 'react-phone-input-2';
import { useSelector, useDispatch } from 'react-redux';
import GoogleAddressAutoComplete from '../views/modules/GoogleMapPlace';

const AWS = require("aws-sdk");
AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IdentityPoolId,
});

const dynamodb = new AWS.DynamoDB.DocumentClient();

const UURL = NewPageId("kkey");

var GetDBData = (params) => {
    try {
        return new Promise((resolve, reject) => {
            dynamodb.query(params, function (err, data) {
                if (err) console.log(err, err.stack); // an error occurred
                else resolve(data); // successful response
            });
        });
    } catch (err) {
        console.log('GetDBData err', err);
    }
};

//var testMail = 'testmail@fakemail.com';
const ValidateEmail = testMail => {

    var check_email = '[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](gmail.com|msn.com|hotmail.com|yahoo.com)';
    var patt = new RegExp(check_email);
    var result = patt.test(testMail);

    console.log("AccountSetup.ValidateEmail", result)
    return result;
}

export default function RadioButtonsGroup(props) {
    const dispatch = useDispatch();
    const ContractorData = useSelector(state => state.ContractorData);
    const WebsiteType = useSelector(state => state.WebsiteType);
    const [DrawerStatus, OpenDrawer] = useState(false);
    const [loading, SetLoading] = useState(false);
    const initialState = {
        AssetList: [],
        AlarmMessage: "",
        OpenAlert: false,
        Name: "",
        Email: "",
        CompanyName: "",
        PlaceId: "",
        NoCompanyEmail: false,
        NeedCompanyEmailAvailable: false,
        Acknowledge: false,
        receiveEmail: true,
        receiveSMS: true,
        AccountId: "",
        receivePhone: true,
        DoneDone: false,
        zapdatas: {
            Category: "Person",
            CompanyId: "event.CompanyId",
            CompanyName: "event.CompanyName",
            AccountId: "state.AccountId",
            Address: "state.Address",
            PersonName: "event.field1",
            PersonPhone: "event.phone",
            PersonMobile: "event.mobile",
            PersonEmail: "event.email",
            date: "event.date",
            TimeZoneName: "event.TimeZoneValue",
        }
    }
    const [state, setState] = useState(initialState);
    //  const [DrawerStatus, OpenDrawer] = useState(false);

    useEffect(() => {
        let timer = "";
        if (state.DoneDone) {
            SetLoading(true)
            timer = setTimeout(() => {
                SetLoading(false)

                dispatch({ type: 'TRIGGER_UPDATE', payload: true });

                if (props.CloseDrawer) {
                    props.CloseDrawer();
                }
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [state.DoneDone]);


    //useEffect(() => {

    //        GetPolicy(ContractorData.CompanyId);        

    //}, [ContractorData.CompanyId])

    //const GetPolicy = async (CompanyId) => {

    //    let DataBack = [];
    //    let Acknowledge = state.Acknowledge;

    //    let getItemParams = {
    //        TableName: "svcbot_db",
    //        KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1) ",
    //        ScanIndexForward: false,
    //        ExpressionAttributeNames: {
    //            "#pk1": "pk1",
    //            "#sk1": "sk1"
    //        },
    //        ExpressionAttributeValues: {
    //            ":pk1": CompanyId,
    //            ":sk1": "Data::Policy::"
    //        }
    //    };

    //    var ContractorFound = await GetDBData(getItemParams);

    //    console.log("AccountSetup.ContractorPolicy", ContractorFound)

    //    if (ContractorFound.Count > 0) {
    //        ContractorFound.Items.map(each => {
    //            DataBack.push(each.dataJSON)
    //        })
    //    } else {
    //        Acknowledge = true
    //    }

    //    setState(prevState => ({
    //        ...prevState,
    //        DataBack: DataBack,
    //        Acknowledge: Acknowledge
    //    }));

    //}

    //const TriggerEmail = () => {

    //    let PhotoService = [];
    //    let Documents = [];

    //    if (state.DataBack && state.DataBack.length > 0) {

    //        state.DataBack.map(each => {
    //            PhotoService = [...PhotoService, ...each.PhotoService]
    //            Documents = [...Documents, ...each.Documents]
    //        })


    //        var emailstring = {

    //            PhotoService: PhotoService,
    //            Documents: Documents,
    //            SpecialInstructions: "",
    //            PreSelect: [],
    //            title: `Policy Documents`,
    //            postHTML: "<h3>Please review following Policy Documents</h3>",
    //            postTEXT: "Please review following Policy Documents",
    //            PersonList: ["ali.rehan@sninc.us"],//[state.Email],
    //            ReplyEmail: "no-reply@service-call-cloud.com", //UserData.email,
    //            PostId: state.NewId,

    //            ContractorAddress: "",
    //            ContractorPhone: "",
    //            ContractorLogo: ContractorData.avatar,
    //            ContractorName: ContractorData.CompanyName,
    //            ContractorId: ContractorData.CompanyId,
    //            CompanyId: ContractorData.CompanyId,
    //            avatar: "",

    //            UnsubscribeLink: `https://app.contractorcheckin.com/Unsubscribe`,
    //            ActionButton: `https://app.contractorcheckin.com`,
    //            ActionButtonTitle: "Online Access",
    //            ActionButtonShow: false,
    //        };

    //        console.log("ItemAddPage.Invite1.email", emailstring)
    //        SendEmail(emailstring);
    //    }
    //}

    const CreatePerson = async () => {
        try {

            // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
            let mobile = state.Mobile;
            // let OldConversation = props.SREvent;
            // let ContractorData = ContractorData;
            let Emailaddress = state.Email;
            let ParseEmail = Emailaddress.split("@");
            // let emailName = ParseEmail[0];
            let Domain = ParseEmail[1];
            let FormdataJSON = {};
            let PersonExist = false;

            //let CCName = ParseEmail[1].split(".");
            //CCName.pop();

            let Acknowledge = "Denied";
            if (state.Acknowledge) {
                Acknowledge = "Accepted";
            }

            let dateToday = new Date();
            let currentTime = new Date(dateToday).getTime();
            const dateAsString = dateToday.toString();
            let TimeZone = dateAsString.match(/\(([^\)]+)\)$/)[1];
            const timezoneOffset = (new Date()).getTimezoneOffset();

            if (ContractorData.TimeZone && ContractorData.TimeZone.value) {
                TimeZone = ContractorData.TimeZone.value;
            }

            if (ValidateEmail(state.Email)) {
                let CDomain = state.CompanyName.replace(/[^a-zA-Z0-9._]/g, "");
                Domain = CDomain.toLocaleLowerCase();
            }

            let CompanyId = `Data::Company::Info::${Domain}::${state.PlaceId}`;

            let ContractorId = ContractorData.CompanyId;
            let ContractorName = ContractorData.CompanyName;

            // let ContName = CCName.replace(/[_.-]/g, ' ');
            let CompanyName = state.CompanyName;  //ContName.titleCase();

            // let PersonName = emailName.replace(/[_.-]/g, ' ');
            let field1 = state.Name;  // PersonName.titleCase();

            let email = Emailaddress;
            let key = `Data::Company::Resource::Person::${WebsiteType}::${email}`;


            //check if person exist?
            let getItemParams = {
                TableName: "svcbot_db",
                KeyConditionExpression: "#pk1 = :pk1 and #sk1 = :sk1",
                ScanIndexForward: false,
                ExpressionAttributeNames: {
                    "#pk1": "pk1",
                    "#sk1": "sk1"
                },
                ExpressionAttributeValues: {
                    ":pk1": key,
                    ":sk1": key
                }
            };

            var PersonFound = await GetDBData(getItemParams);

            console.log("AccountSetup.PersonFound", PersonFound)

            if (PersonFound.Count > 0) {
                FormdataJSON = PersonFound.Items[0].dataJSON;
                PersonExist = true;
                CompanyId = FormdataJSON.CompanyId
            }

            let CompanyInfo = {
                CompanyId: CompanyId,
                CustomerType: "Company",
                ContractorId: ContractorId,
                ContractorName: ContractorName
            }

            if (!PersonExist) {

                let phone = mobile;
                // let CompanyName = ContractorName;
                let field2 = email;
                // let TimeZone = ContractorData.TimeZone;
                // let CompanyId = Contractorkey;
                let SearchWords = `${field1} ${email} ${CompanyName}`;
                let PostId = NewPageId("Person");
                let Privileges = {
                    "label": "Manager",
                    "value": 300
                };
                let posterId = {
                    field1: field1,
                    field2: field2,
                    CompanyId: CompanyId,
                    CompanyName: CompanyName,
                    avatar: "",
                    key: key,
                }

                let AllItemForms = [
                    {
                        "sk1": key,
                        "Category": "PersonCheckin",
                        "Documents": [
                        ],
                        "field1": "Person Infomation",
                        "FormData": [
                            {
                                "Category": "PersonCheckin",
                                "defaultValue": field1,
                                "Editable": false,
                                "extra": "field1",
                                "key": "ff_p6ZcrMay10",
                                "label": "Name",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 1,
                                "required": true,
                                "Searchable": true,
                                "ShowValue": field1,
                                "TableColumn": true,
                                "type": "textline",
                                "VariableInput": field1,
                                "VariableName": "name"
                            },
                            {
                                "Category": "PersonCheckin",
                                "defaultValue": email,
                                "Editable": false,
                                "extra": "field2",
                                "key": "ff_p6ZcrMay11",
                                "label": "Email",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 1,
                                "required": true,
                                "Searchable": true,
                                "ShowValue": email,
                                "TableColumn": true,
                                "type": "email",
                                "VariableInput": email,
                                "VariableName": "email"
                            },
                            {
                                "Category": "PersonCheckin",
                                "defaultValue": TimeZone,
                                "Details": TimeZone,
                                "Editable": false,
                                "key": "ff_p6ZcrMay12",
                                "label": "Time Zone",
                                "options": [
                                ],
                                "Order": 1,
                                "required": true,
                                "Searchable": true,
                                "ShowValue": TimeZone,
                                "TableColumn": true,
                                "type": "TimeZone",
                                "VariableInput": TimeZone,
                                "VariableName": "TimeZone"
                            },
                            {
                                "Category": "PersonCheckin",
                                "defaultValue": mobile,
                                "Editable": true,
                                "key": "ff_p6ZcrMay13",
                                "label": "Mobile/SMS",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 3,
                                "required": true,
                                "ShowValue": mobile,
                                "TableColumn": false,
                                "type": "phone",
                                "VariableInput": mobile,
                                "VariableName": "mobile"
                            },
                            {
                                "Category": "PersonCheckin",
                                "defaultValue": phone,
                                "Editable": true,
                                "key": "ff_p6ZcrMay14",
                                "label": "Work Phone",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 3,
                                "required": true,
                                "ShowValue": phone,
                                "TableColumn": false,
                                "type": "phone",
                                "VariableInput": phone,
                                "VariableName": "phone"
                            },
                            {
                                "Category": "PersonCheckin",
                                "defaultValue": Acknowledge,
                                "Editable": true,
                                "key": "ff_p6ZcrMay15",
                                "label": "Acknowledge Policy",
                                "ListInfo": true,
                                "options": [
                                ],
                                "Order": 3,
                                "required": true,
                                "ShowValue": Acknowledge,
                                "TableColumn": false,
                                "type": "checkbox",
                                "VariableInput": Acknowledge,
                                "VariableName": "Policy"
                            },
                            //{
                            //    Order: 3,
                            //    options: [],
                            //    label: "Receive Email Notification",
                            //    VariableName: "receiveEmail",
                            //    Editable: true,
                            //    TableColumn: false,
                            //    extra: "",
                            //    VariableInput: state.receiveEmail,
                            //    defaultValue: state.receiveEmail,
                            //    message: "",
                            //    type: "checkbox",
                            //    key: 3,
                            //    Category: "PersonCheckin",
                            //    ListInfo: true,
                            //    required: true
                            //},
                            //{
                            //    Order: 3,
                            //    options: [],
                            //    label: "Receive Text/SMS Notification",
                            //    VariableName: "receiveSMS",
                            //    Editable: true,
                            //    TableColumn: false,
                            //    extra: "",
                            //    VariableInput: state.receiveSMS,
                            //    defaultValue: state.receiveSMS,
                            //    message: "",
                            //    type: "checkbox",
                            //    key: 3,
                            //    Category: "PersonCheckin",
                            //    ListInfo: true,
                            //    required: true
                            //},
                            //{
                            //    Order: 3,
                            //    options: [],
                            //    label: "Receive Phone Notification",
                            //    VariableName: "receivePhone",
                            //    Editable: true,
                            //    TableColumn: false,
                            //    extra: "",
                            //    VariableInput: state.receivePhone,
                            //    defaultValue: state.receivePhone,
                            //    message: "",
                            //    type: "checkbox",
                            //    key: 3,
                            //    Category: "PersonCheckin",
                            //    ListInfo: true,
                            //    required: true
                            //}
                        ],
                        "FormName": "Person Infomation",
                        "FormType": "Person",
                        "key": key,
                        "PhotoService": [
                        ],
                        "SearchWords": "x",
                        "ValueAdded": true
                    },

                ];

                //  console.log("AllItemForms 421", JSON.stringify(AllItemForms, null, 2));

                FormdataJSON = {
                    AllItemForms: AllItemForms,
                    BranchId: ContractorId,
                    ContractorDataId: ContractorId,
                    BranchName: ContractorName,
                    ContractorName: ContractorName,
                    HQId: "DeveloperId",
                    HQName: "DeveloperId",
                    status: "Waiting",
                    SearchWords: SearchWords,
                    timezoneOffset: timezoneOffset,
                    AllowSMSService: true,
                    Category: "Person",
                    CustomerType: "Company",
                    TimeZone: TimeZone,
                    CompanyId: CompanyId,
                    CompanyName: CompanyName,
                    avatar: "",
                    id: PostId,
                    Location: state.Location,
                    key: key,
                    PersonId: key,
                    Privileges: Privileges,
                    Level: 300,
                    field1: field1,
                    field2: field2,
                    ContractorId: ContractorId,
                    receiveEmail: state.receiveEmail,
                    receiveSMS: state.receiveSMS,
                    receivePhone: state.receivePhone,
                    date: currentTime,
                    posterId: posterId,
                    PhotoService: [],
                    Documents: [],
                    phone: phone,
                    mobile: mobile,
                    email: email,
                    pk1: CompanyId,
                    sk1: key,
                    gsi1Pk2: `phone::${WebsiteType}::${mobile}`,
                    gsi1Sk2: ContractorId,
                    gsi2Pk2: key,
                    gsi2Sk2: ContractorId,
                };

                let PersonInfo = {

                    BranchId: ContractorId,
                    BranchName: ContractorName,
                    ContractorName: ContractorName,
                    HQId: "DeveloperId",
                    HQName: "ContractorCheckIn.com",
                    Category: "Person",
                    CustomerType: "Company",
                    TimeZone: TimeZone,
                    CompanyId: CompanyId,
                    CompanyName: CompanyName,
                    avatar: "",
                    id: PostId,
                    key: key,
                    Privileges: Privileges,
                    Level: 100,
                    field1: field1,
                    field2: field2,
                    ContractorId: ContractorId,
                    receiveEmail: state.receiveEmail,
                    receiveSMS: state.receiveSMS,
                    receivePhone: state.receivePhone,
                    date: currentTime,
                    posterId: posterId,
                    PhotoService: [],
                    Documents: [],
                    phone: phone,
                    mobile: mobile,
                    email: email
                };

                console.log("AddCustomer.AddPerson", FormdataJSON);

                //OldConversation.posterId = PersonInfo;
                //OldConversation.WaitForEmail = false;
                //  CheckOnCompany(FormdataJSON, "CreatePerson");

                // dispatch({ type: 'TRIGGER_UPDATE', payload: true });

                //setState(prevState => ({
                //    ...prevState,
                //    DoneDone: true,
                //}))

                // TriggerEmail();

                //let SelectedDataReturn = {
                //    ...state,
                //    id: props.id,
                //    KeyId: props.KeyId
                //}

                //console.log("AccountSetup.key", SelectedDataReturn)

                //if (props.SelectedDataReturn) {
                //    props.SelectedDataReturn(OldConversation)
                //}

                //if (props.handleNext) {
                //    props.handleNext();
                //}

                let DBInput = {
                    pk1: key,
                    sk1: key,
                    gsi1Pk2: `phone::${WebsiteType}::${mobile}`,
                    gsi1Sk2: key,
                    gsi2Pk2: CompanyId,
                    gsi2Sk2: key,
                    dataJSON: JSON.stringify(removeEmptyStringElements(FormdataJSON))
                };

                // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
                let PosttoDB = {
                    MutationType: "createSvcbotDb",
                    DBData: DBInput
                };

                // let Messagereceived = `Account created, please refresh the Person list on the app`;
                // MessageBackSMS(TwilioSMSData.To, TwilioSMSData.From, Messagereceived);

                    
                

                // Post to DB
                TriggerLambda(PosttoDB);

                ZapPerson(FormdataJSON)
            }

            CheckOnCompany(FormdataJSON, CompanyInfo);

            CreatePersonAccunts(FormdataJSON, CompanyInfo)


        } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }


    }

    const ZapPerson = async (event) => {

        try {

            let getItemParams2 = {
                IndexName: "gsi1Pk2-gsi1Sk2-index",
                TableName: "svcbot_db",
                Limit: 10,
                ScanIndexForward: false,
                KeyConditionExpression: "#gsi1Pk2 = :gsi1Pk2 and #gsi1Sk2 = :gsi1Sk2",
                ExpressionAttributeNames: {
                    "#gsi1Pk2": "gsi1Pk2",
                    "#gsi1Sk2": "gsi1Sk2",
                },
                ExpressionAttributeValues: {
                    ":gsi1Pk2": event.ContractorId,
                    ":gsi1Sk2": "Zapier::addperson::Subscribe"
                }
            };
            // console.log("SelectedArray5.getItemParams2 ---", getItemParams2);
            let ZapierCheck = await GetDBData(getItemParams2);

            console.log("MessageSentList", JSON.stringify(ZapierCheck, null, 2))

            if (ZapierCheck.Count > 0
                && event.CustomerType === "Company") {

                let ZapInfo = ZapierCheck.Items[0];
                let ZapdataJSON = ZapInfo.dataJSON;

                const workordercode = {
                    Category: "Person",
                    CompanyId: event.CompanyId,
                    CompanyName: event.CompanyName,
                    AccountId: state.AccountId,
                    Address: state.Address,
                    PersonName: event.field1,
                    PersonPhone: event.phone,
                    PersonMobile: event.mobile,
                    PersonEmail: event.email,
                    date: event.date,
                    TimeZoneName: event.TimeZoneValue,
                };

                let ZapData = {
                    ZapTrigger: ZapdataJSON,
                    ZapData: workordercode
                }

                TriggerZapSendData(ZapData);

                console.log('Zapier Data', JSON.stringify(ZapData, null, 2));
            }

        } catch (err) {
            console.log('Zapier err', JSON.stringify(err, null, 2));
        }


    }

    const ZapCompany = async (event) => {

        try {

            let getItemParams2 = {
                IndexName: "gsi1Pk2-gsi1Sk2-index",
                TableName: "svcbot_db",
                Limit: 10,
                ScanIndexForward: false,
                KeyConditionExpression: "#gsi1Pk2 = :gsi1Pk2 and #gsi1Sk2 = :gsi1Sk2",
                ExpressionAttributeNames: {
                    "#gsi1Pk2": "gsi1Pk2",
                    "#gsi1Sk2": "gsi1Sk2",
                },
                ExpressionAttributeValues: {
                    ":gsi1Pk2": event.ContractorId,
                    ":gsi1Sk2": "Zapier::addcompany::Subscribe"
                }
            };
            // console.log("SelectedArray5.getItemParams2 ---", getItemParams2);
            let ZapierCheck = await GetDBData(getItemParams2);

            console.log("MessageSentList", JSON.stringify(ZapierCheck, null, 2))

            if (ZapierCheck.Count > 0
                && event.CustomerType === "Company") {

                let ZapInfo = ZapierCheck.Items[0];
                let ZapdataJSON = ZapInfo.dataJSON;

                const workordercode = {
                    Category: "Company",
                    CompanyId: event.CompanyId,
                    CompanyName: event.CompanyName,
                    AccountId: state.AccountId,
                    Address: state.Address,
                    date: event.date,
                    TimeZoneName: event.TimeZoneValue,
                };

                let ZapData = {
                    ZapTrigger: ZapdataJSON,
                    ZapData: workordercode
                }

                TriggerZapSendData(ZapData);

                console.log('Zapier Data', JSON.stringify(ZapData, null, 2));
            }

        } catch (err) {
            console.log('Zapier err', JSON.stringify(err, null, 2));
        }


    }

    const TriggerZapSendData = (event) => {
        console.log("NewServiceCall.ZapSendData", event);

        API.post("ZapSendData", "", {
            body: { Records: event }
        });
    }

    //const CheckOnCompany = async (PersonData, from) => {

    //    console.log('CheckOnCompany', from);

    //    let PersonCompany = await CheckCompanyExist("Developer::Account::0786", PersonData.CompanyId);

    //    console.log('CheckOnCompany', from, PersonCompany.exist);

    //    if (PersonCompany && PersonCompany.exist) {

    //        //check if it exist under contactor
    //        let ExistUnder = await CheckCompanyExist(ContractorData.CompanyId, `Under::${PersonData.CompanyId}`);

    //        if (ExistUnder && ExistUnder.exist) {

    //            setState(prevState => ({
    //                ...prevState,
    //                DoneDone: true,
    //            }))

    //        } else {
    //            //create company under contraactor
    //            await Promise.all([
    //                CreateUnderOwner(PersonData, PersonCompany.CompanyData),
    //                CreatePersonAccunts(PersonData, PersonCompany.CompanyData)])

    //        }

    //    } else {
    //        await CreateCompany(PersonData)
    //    }

    //    return true;

    //}

    const CheckOnCompany = async (FormdataJSON, CompanyInfo) => {

        let Companyresult = await CheckCompanyExist("Developer::Account::0786", CompanyInfo.CompanyId);

        if (Companyresult && Companyresult.exist) {

            //if not a new contractor signup
            if (FormdataJSON.CustomerType !== "Contractor") {

                //check if it exist under contactor
                let ExistUnder = await CheckCompanyExist(ContractorData.ContractorId, `Under::${CompanyInfo.CompanyId}`);
                console.log("AccountSetup.CheckCompanyExist.ExistUnder", ExistUnder);

                if (ExistUnder && ExistUnder.exist) {

                    setState(prevState => ({
                        ...prevState,
                        DoneDone: true,
                    }))

                } else {

                    CreateUnderOwner(CompanyInfo, Companyresult.CompanyData)
                }

            } else {

                setState(prevState => ({
                    ...prevState,
                    DoneDone: true,
                }))
            }

        } else {

           CreateCompany(FormdataJSON)
        }

}

const CheckCompanyExist = async (pk1, sk1) => {

    let getItemParams = {
        TableName: "svcbot_db",
        KeyConditionExpression: "#pk1 = :pk1 and begins_with(#sk1, :sk1) ",
        ScanIndexForward: false,
        ExpressionAttributeNames: {
            "#pk1": "pk1",
            "#sk1": "sk1"
        },
        ExpressionAttributeValues: {
            ":pk1": pk1,
            ":sk1": sk1
        }
    };

    var ContractorFound = await GetDBData(getItemParams);

    if (ContractorFound.Count > 0) {
        let FormdataJSON = ContractorFound.Items[0].dataJSON;
        return {
            exist: true,
            CompanyData: FormdataJSON
        };
        //create under owner
        //  CreateUnderOwner(data, ContractorFound)
    } else {
        //create company
        return {
            exist: false,
            CompanyData: ""
        };

    }
}

const CreateUnderOwner = (data, CompanyData) => {
    try {

        let dataJSON = {
            ...CompanyData,
            pk1: ContractorData.CompanyId,
            sk1: `Under::` + data.CompanyId,
            gsi1Pk2: data.CompanyId,
            gsi1Sk2: ContractorData.CompanyId,
            gsi2Pk2: data.CompanyId,
            gsi2Sk2: ContractorData.CompanyId,
        }

        let DBInput = {
            pk1: ContractorData.CompanyId,
            sk1: `Under::` + data.CompanyId,
            gsi1Pk2: data.CompanyId,
            gsi1Sk2: ContractorData.CompanyId,
            gsi2Pk2: data.CompanyId,
            gsi2Sk2: ContractorData.CompanyId,
            dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
        };

        console.log("AccountSetup.Under", DBInput);

        // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
        let PosttoDB = {
            MutationType: "createSvcbotDb",
            DBData: DBInput
        };

        // Post to DB
        TriggerLambda(PosttoDB);

        setState(prevState => ({
            ...prevState,
            DoneDone: true,
        }))

        return true;

    } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }

}

const CreatePersonAccunts = (data, CompanyInfo) => {
    try {

        console.log("CreatePersonAccunts.CompanyInfo", CompanyInfo)

        let InputArray = [];

        let dataJSON = {
            AssetPkSk: {
                pk1: "Developer::Account::0786",
                sk1: CompanyInfo.ContractorId
            },
            pk1: `Account::` + data.sk1,
            sk1: CompanyInfo.ContractorId,
            gsi1Pk2: data.sk1,
            gsi1Sk2: `Account::` + CompanyInfo.ContractorId,
            gsi2Pk2: CompanyInfo.CompanyId,
            gsi2Sk2: `Account::` + CompanyInfo.ContractorId,
            CustomerType: data.CustomerType,
            CompanyName: data.CompanyName,
            key: CompanyInfo.ContractorId,
            PersonId: data.pk1,
            ContractorId: CompanyInfo.ContractorId,
            CompanyId: data.CompanyId,
            field1: CompanyInfo.ContractorName,
            field2: "-",
            Category: "ContractorAccount",   // Company's person signed up with contractor - list contractors at sign-in
        }



        let DBInput = {
            pk1: `Account::` + data.sk1,
            sk1: CompanyInfo.ContractorId,
            gsi1Pk2: data.sk1,
            gsi1Sk2: `Account::` + CompanyInfo.ContractorId,
            gsi2Pk2: CompanyInfo.CompanyId,
            gsi2Sk2: `Account::` + CompanyInfo.ContractorId,
            dataJSON: JSON.stringify(removeEmptyStringElements(dataJSON))
        };

        InputArray.push(DBInput);

        //this shows up in contractor page
        let PersonJson = {
            AssetPkSk: {
                pk1: data.pk1,
                sk1: data.sk1
            },
            PersonId: data.pk1,
            CompanyId: data.CompanyId,
            field2: data.field2,
            field1: data.field1,
            CustomerType: data.CustomerType,
            CompanyName: data.CompanyName,
            pk1: `Account::` + data.sk1,
            sk1: data.sk1,
            gsi1Pk2: data.CompanyId,
            gsi1Sk2: CompanyInfo.ContractorId,
            gsi2Pk2: CompanyInfo.ContractorId,
            gsi2Sk2: `Account::` + data.CompanyId,
            Category: "CompanyPersonnel",  // company personal in Contractor system - show only when contractor is looking
        }

        let PersonIdInput = {
            pk1: `Account::` + data.sk1,
            sk1: data.sk1,
            gsi1Pk2: data.CompanyId,
            gsi1Sk2: CompanyInfo.ContractorId,
            gsi2Pk2: CompanyInfo.ContractorId,
            gsi2Sk2: `Account::` + data.CompanyId,
            dataJSON: JSON.stringify(removeEmptyStringElements(PersonJson))
        };

        InputArray.push(PersonIdInput);

        console.log("CreatePersonAccunts.InputArray", InputArray)

        // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
        let PosttoDB = {
            MutationType: "createBatch",
            DBData: InputArray
        };

        // Post to DB
        TriggerLambda(PosttoDB, "CreateUnderOwner");

        return true;

    } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }

}

const CreateCompany = (data) => {
    try {

        let SearchWords = data.CompanyName;
        let PostId = NewPageId("3933kiqPPwikskkLLA");

        let AllItemForms = [
            {
                "sk1": data.CompanyId,
                "Category": "Company",
                "Documents": [
                ],
                "FormData": [
                    {
                        "Category": "Company",
                        "defaultValue": data.CompanyName,
                        "Editable": false,
                        "extra": "field1",
                        "key": 100,
                        "label": "Company Name",
                        "options": [
                        ],
                        "Order": 1,
                        "required": true,
                        "Searchable": true,
                        "TableColumn": true,
                        "type": "textline",
                        "VariableInput": data.CompanyName,
                        "VariableName": "CompanyName"
                    },
                    {
                        "Category": "Company",
                        "defaultValue": "",
                        "Editable": true,
                        "extra": "field2",
                        "key": 101,
                        "label": "Business Type",
                        "options": [
                        ],
                        "Order": 1,
                        "required": false,
                        "TableColumn": false,
                        "type": "textline",
                        "VariableInput": "",
                        "VariableName": "BusinessType"
                    },
                    {
                        "Category": "Company",
                        "defaultValue": "",
                        "Editable": true,
                        "extra": "",
                        "key": 101,
                        "label": "Account Id (Optional from Your Database)",
                        "options": [
                        ],
                        "Order": 1,
                        "required": false,
                        "TableColumn": false,
                        "type": "textline",
                        "VariableInput": state.AccountId,
                        "VariableName": "AccountId"
                    },

                    {
                        "Category": "Company",
                        "defaultValue": "",
                        "Editable": true,
                        "extra": "",
                        "key": 101112,
                        "label": "Address",
                        "options": [
                        ],
                        "Order": 1,
                        "required": false,
                        "TableColumn": false,
                        "type": "GoogleAddressAutoComplete",
                        "VariableInput": state.Location,
                        "VariableName": "Location"
                    },
                ],
                "FormName": "Company Infomation",
                "FormType": "Company",
                "key": data.CompanyId,
                "PhotoService": [
                ],
                "ValueAdded": true
            }
        ];

        //  console.log("AllItemForms 421", JSON.stringify(AllItemForms, null, 2));

        let FormdataJSON = {
            ...data,
            AllItemForms: AllItemForms,
            status: "Active",
            SearchWords: SearchWords,
            AllowSMSService: true,
            AccountId: state.AccountId,
            Category: "Company",
            CustomerType: "Company",
            id: PostId,
            key: data.CompanyId,
            field1: data.CompanyName,
            field2: state.Address,
            pk1: "Developer::Account::0786",
            sk1: data.CompanyId,
            //gsi1Pk2: data.CompanyId,
            //gsi1Sk2: data.ContractorId,
            //gsi2Pk2: data.ContractorId,
            //gsi2Sk2: data.CompanyId,
        };

        let InputArray = []

        let dataJSON = JSON.stringify(removeEmptyStringElements(FormdataJSON))

        console.log("AccountSetup.CreateCompany", FormdataJSON);

        let DBInput = {
            pk1: "Developer::Account::0786",
            sk1: data.CompanyId,
            //gsi1Pk2: data.CompanyId,
            //gsi1Sk2: data.ContractorId,
            //gsi2Pk2: data.ContractorId, 
            //gsi2Sk2: data.CompanyId,
            dataJSON: dataJSON
        };

        InputArray.push(DBInput);

        //DBInput = {
        //    pk1: data.ContractorId,
        //    sk1: data.CompanyId,
        //    gsi1Pk2: data.CompanyId,
        //    gsi1Sk2: data.ContractorId,
        //    gsi2Pk2: data.ContractorId,
        //    gsi2Sk2: data.CompanyId,
        //    dataJSON: dataJSON
        //};

        //InputArray.push(DBInput);

        // let PosttoDBLambda = "arn:aws:lambda:us-east-1:109857144948:function:PosttoDB";
        let PosttoDB = {
            MutationType: "createBatch",
            DBData: InputArray
        };

        // let Messagereceived = `Account created, please refresh the Person list on the app`;
        // MessageBackSMS(TwilioSMSData.To, TwilioSMSData.From, Messagereceived);

        // Post to DB
        TriggerLambda(PosttoDB);

        ZapCompany(FormdataJSON);

        CreateUnderOwner(data, FormdataJSON)

        setState(prevState => ({
            ...prevState,
            DoneDone: true,
        }))

        return true;


    } catch (err) { console.log("put.err", JSON.stringify(err, null, 2)) }


}

const TriggerLambda = (event) => {
    console.log("NewServiceCall.TriggerLambda", event);

    API.post("PostToDB", "", {
        body: { Records: event }
    });
}

const SetValues = (data, name) => {

    if (name === "Email" && data !== "") {
        data = data.toLowerCase();
    }

    if (name === "Location" && data.place_id !== "") {
        setState(prevState => ({
            ...prevState,
            PlaceId: data.place_id,
            Address: data.address
        }));
    }

    setState(prevState => ({
        ...prevState,
        [name]: data
    }));

}

const handleNext = () => {

    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    let AlarmMessage = "";
    let OpenAlert = false;
    let NeedCompanyEmailAvailable = false;

    if (state.Name === "") {
        OpenAlert = true;
        AlarmMessage += " * Please enter Name ";
    }

    if (state.Mobile === "") {
        OpenAlert = true;
        AlarmMessage += " * Please enter Mobile ";
    }

    //if (!state.Acknowledge) {
    //    OpenAlert = true;
    //    AlarmMessage += " * Please Acknowledge Policy Documents ";
    //}

    if (state.CompanyName === "") {
        OpenAlert = true;
        AlarmMessage += " * Please enter Company Name ";
    }

    if (state.Email === "") {
        OpenAlert = true;
        AlarmMessage += " * Please enter Email ";
    }

    if (!emailRegexp.test(state.Email)) {
        OpenAlert = true;
        AlarmMessage += " * Please enter Company Email ";
    }

    if (ValidateEmail(state.Email)) {
        if (!state.NoCompanyEmail) {
            OpenAlert = true;
            AlarmMessage += " * Gmail, MSN, HotMail, Yahoo not preferred. Please use company email, or mark the check box ";
            NeedCompanyEmailAvailable = true;
        }
    }

    if (state.Address === "") {
        OpenAlert = true;
        AlarmMessage += " * Please enter Company Address ";
    }

    if (OpenAlert) {

        setState(prevState => ({
            ...prevState,
            AlarmMessage: AlarmMessage,
            OpenAlert: OpenAlert,
            NeedCompanyEmailAvailable: NeedCompanyEmailAvailable
        }));

    } else {

        CreatePerson();

    }

}

const CheckIfPersonExist = async () => {

    let key = `Data::Company::Resource::Person::${WebsiteType}::${state.Email}`;

    let getItemParams = {
        TableName: "svcbot_db",
        KeyConditionExpression: "#pk1 = :pk1",
        ScanIndexForward: false,
        ExpressionAttributeNames: {
            "#pk1": "pk1"
        },
        ExpressionAttributeValues: {
            ":pk1": key
        }
    };

    var PersonFound = await GetDBData(getItemParams);

    console.log("AccountSetup.ContractorPolicy", PersonFound)

    if (PersonFound.Count > 0) {
        let FormdataJSON = PersonFound.Items[0].dataJSON;
        CheckOnCompany(FormdataJSON, FormdataJSON);
        // CreatePersonAccunts(FormdataJSON);

    } else {
        CreatePerson();
    }

}

const handleClose = (event, reason) => {

    setState(prevState => ({
        ...prevState,
        AlarmMessage: "",
        OpenAlert: false
    }));

};

const AddBox = (add) => {
    console.log("DocumentPage.AddBox", add);
    setState(prevState => ({
        ...prevState,
        DrawerTitle: "Document Details",
        // SetupSubscription: true,
        DetailComponent: "ShowDetailsText",
        ItemData: add,
        DrawerKey: NewPageId("DrawerKey"),
        NewCategory: "ShowDetails",
    }))

    OpenDrawer(true);
    //}
}

const CloseDrawer = (c) => {
    //    GetDatafromDB(state.Category, state.pk1);
    OpenDrawer(false);
}


console.log("AccountSetup.state", state)
console.log("AccountSetup.props", props)

return (
    <>

        <Card>
            <CardHeader
                title="Add Customer"
                subheader="Add a person from the company. This step will add the company and the person into the system. Additional personnel can be added by selecting the company name and going to Personnel tab"

            />
            <CardContent>

                <Grid container spacing={1}>

                    <Grid item xs={12} >
                        <TextField
                            label="Person Name"
                            name="Name"
                            value={state.Name || ""}
                            fullWidth
                            variant="outlined"
                            onChange={(e) => SetValues(e.target.value, "Name")}
                        />

                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Email"
                            name="Email"
                            value={state.Email || ""}
                            fullWidth
                            variant="outlined"
                            onChange={(e) => SetValues(e.target.value, "Email")}
                        />

                    </Grid>

                    {state.NeedCompanyEmailAvailable ? (
                        <Grid item xs={12} >
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox onChange={(e) => SetValues(e.target.checked, "NoCompanyEmail")} />}
                                    label="Company Email not availabe" />
                            </FormGroup>

                        </Grid>
                    ) : null}

                    <Grid item xs={12} >

                        <InputLabel id="phone1121">Mobile</InputLabel>
                        <PhoneInput
                            country={'us'}
                            key="phonekeyysak"
                            id="phonekeyysassak"
                            //   dropdownClass={classes.countryList}
                            disabled={false}
                            label="Mobile"
                            inputProps={{
                                name: state.Mobile,
                                label: "Mobile",
                                required: true,
                                style: { width: '100%' },
                                //value: Item.VariableInput || ""
                                //error: ErrorMessage
                            }}
                            isValid={(value, country) => {
                                if (value.match(/12345/)) {
                                    return 'Invalid value: ' + value + ', ' + country.name;
                                } else if (value.match(/1234/)) {
                                    return false;
                                } else {
                                    return true;
                                }
                            }}
                            value={state.Mobile}
                            onChange={(e) => SetValues('+' + e, "Mobile")}

                        />

                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            label="Company Name"
                            name="CompanyName"
                            value={state.CompanyName || ""}
                            fullWidth
                            variant="outlined"
                            onChange={(e) => SetValues(e.target.value, "CompanyName")}
                        />

                    </Grid>

                    <Grid item xs={12} >
                        <TextField
                            label="CRM or Work Order System Account Id (optional)"
                            name="AccountId"
                            value={state.AccountId || ""}
                            fullWidth
                            variant="outlined"
                            onChange={(e) => SetValues(e.target.value, "AccountId")}
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <InputLabel >Address</InputLabel>
                        <GoogleAddressAutoComplete
                            key={UURL}
                            defaultValue={state.Address || ""}
                            // error={ErrorMessage}
                            autocomplete="off"
                            disabled={state.disabled}
                            label="Address"
                            AddressObj={(e) => SetValues(e, "Location")}
                        />
                    </Grid>

                    {/*
                <Grid item xs={12} >
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(e) => SetValues(e.target.checked, "receiveEmail")}
                                    checked={state.receiveEmail}
                            />} label="Opt in - Email" />
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(e) => SetValues(e.target.checked, "receiveSMS")}
                                    checked={state.receiveSMS}
                            />} label="Opt in - Text Messages" />
                        <FormControlLabel
                            control={<Checkbox
                                onChange={(e) => SetValues(e.target.checked, "receivePhone")}
                                    checked={state.receivePhone}
                            />} label="Opt in - Phone Calls" />
                    </FormGroup>
                    </Grid>



                    <Grid item xs={12} >

                        <Card>
                            <CardHeader
                                title="Policy Document List"
                                subheader="Please acknowledge"

                            />
                            <CardContent>
                                <Grid container spacing={1}>

                                    {state.DataBack && state.DataBack.length > 0 ? (
                                        <>

                                            <Grid item xs={12} >
                                                <Typography variant="body1" gutterBottom>
                                                    {`An email will be sent with link to all the Policy documents, please click on Acknowledge check box`}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} >
                                                <ListModule
                                                    title="Policy Documents"
                                                    //  ShowDetailLink={false}
                                                    AllowDelete={false}
                                                    ShowDetailLink={true}
                                                    DetailLink={(e) => AddBox(e)}
                                                    disabled={false}
                                                    DataBack={state.DataBack}
                                                />
                                            </Grid>

                                            <Grid item xs={12} >
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            value={state.Acknowledge}
                                                            onChange={(e) => SetValues(e.target.checked, "Acknowledge")} />} label="Acknowledge" />
                                                </FormGroup>
                                            </Grid>
                                        </>
                                    ): null}
                                </Grid>
                            </CardContent>
                        </Card>

                    </Grid>
                */}

                    <Grid item xs={12}>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleNext()}
                        //  className={classes.button}
                        >
                            Submit
                        </Button>

                    </Grid>

                    {/* 
                        <Grid item xs={12}>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => TriggerZapSendData(state.zapdatas)}
                            //  className={classes.button}
                            >
                                test zapier data
                            </Button>

                        </Grid>
                        */}

                </Grid>

            </CardContent>
        </Card>

        <Snackbar
            open={state.OpenAlert}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            message={state.AlarmMessage}
        />

        <Backdrop
            // className={classes.backdrop}
            open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>

        <DrawerShow
            LoadComponenet="ShowDetailsText"
            CloseDrawer={(e) => CloseDrawer(e)}
            Category="Policy"
            DrawerTitle="Policy Document"
            propss={{
                Category: "Policy",
                CompanyId: state.CompanyId,
                CompanyName: state.CompanyName,
                CloseDrawer: (e) => CloseDrawer(e),
                //  TriggerUpdate: true,
                //  SetupSubscription: state.SetupSubscription,
                Data: state.ItemData,
                // SubData: state.SubData,
                id: state.DrawerKey,
                // AddItem: () => AddItem("ItemAddPage", "Person")
            }}
            key={state.DrawerKey}
            DrawerStatus={DrawerStatus}
            OpenDrawer={(e) => OpenDrawer(e)}
            anchor={state.anchor || "left"}
        />

    </>

);
}
